import DesktopNav from "components/Navbars/DesktopNav";
import React from "react";
import "../../assets/css/landing-page-styles.css";
import HeroSection from "components/LandingPage/HeroSection";

const LandingPage = () => {
    return (
        <div className="landingContainer">
            <DesktopNav/>
            <HeroSection/>
        </div>
    );
}

export default LandingPage;