import {
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
  InputGroup,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { Link } from "react-router-dom";
import Paginations from "components/Pagination/Paginations";
import { getTeachersBySearch } from "actions/Teachers";
import { getTeachersByLimit } from "actions/Teachers";
import { getAllTeachers } from "actions/Teachers";
import "../../../assets/css/styles.css";

const Teachers = () => {

  const [allTeachers, setAllTeachers] = useState(null);
  const [allActiveTeachers, setAllActiveTeachers] = useState(null);
  const [isAllTeachers, setIsAllTeachers] = useState(false);
  const [isLoadingTeachers, setLoadingTeachers] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, SetItemsPerPage] = useState(10);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");

  const getTeachers = async (itemsPerPge, skip) => {
    const teachers = await getTeachersByLimit(itemsPerPge, skip);
    setPageCount(teachers.data.pageCount);
    setAllTeachers(teachers.data.data);
    setLoadingTeachers(false);
    const teacherData = teachers.data.data;
    if (teacherData.length > 0) {
      setIsAllTeachers(true);
    }
  }

  const fetchData = async () => {
    try {
      await getTeachers(itemsPerPge, skip);
      const allActiveTeachers = await getAllTeachers();
      setAllActiveTeachers(allActiveTeachers)
    } catch (error) {
      setLoadingTeachers(false);
      setIsAllTeachers(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const searchCategory = async (searchQuery) => {
    const searched = await getTeachersBySearch(itemsPerPge, skip, searchQuery);
    setPageCount(searched.data.pageCount);
    setAllTeachers(searched.data.data);
  };

  const filterBySearch = async (itemsPerPge, skip, searchQuery) => {
    const searched = await getTeachersBySearch(itemsPerPge, skip, searchQuery);
    setPageCount(searched.data.pageCount);
    setAllTeachers(searched.data.data);
    setCurrentPage(skip + 1);
  }

  const handlePagination = async (pageNumber) => {
    if (searchQuery !== " ") {
      await filterBySearch(itemsPerPge, pageNumber - 1, searchQuery);
    } else {
      await fetchData(itemsPerPge, pageNumber - 1);
      setCurrentPage(pageNumber);
    }
  }

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        {isLoadingTeachers ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isAllTeachers ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data Available" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="border-0">
              <Row>
                <Col>
                  <h3 className="mb-0">Teachers</h3>
                </Col>
                <Col className="justify-content-end" md="8" lg="4">
                  <InputGroup className="mb-3">
                    <Input
                      type="text"
                      placeholder="Search by full name"
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setCurrentPage(1);
                        setCurrentStart(0);
                        searchCategory(e.target.value);
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Full Name</th>
                  <th scope="col">Contact Number</th>
                  <th scope="col">Status</th>
                  <th colSpan={4} style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {allTeachers?.map((teacher, index) => (
                  <tr key={index}>
                    <td>{teacher.full_name}</td>
                    <td>{teacher.phone_number}</td>
                    <td>{teacher.status}</td>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        color="default"
                        type="button"
                        tag={Link}
                        to={`/high-level-admin/teachers/${teacher.id}`}
                      >
                        Class Details
                      </Button>
                      <Button
                        color="default"
                        type="button"
                        tag={Link}
                        to={`/high-level-admin/teachers/payments/${teacher.id}/${teacher.full_name}`}
                      >
                        Payments
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ paddingTop: "10px" }}>
              {pageCount > 1 ?
                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                  currentStart={currentStart}
                  setCurrentStart={setCurrentStart}></Paginations>
                : null}
            </div>
          </Card>
        )}
      </div>
    </>
  );
};

export default Teachers;
