import {
    Table,
    Card,
    CardHeader,
    Input,
    Row,
    Col,
    Spinner,
    InputGroup,
    Button,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { useParams } from "react-router-dom";

import "../../../assets/css/styles.css";
import Paginations from "components/Pagination/Paginations";
import { getClassStudentsByLimit } from "actions/Students";
import { getClassStudentsBySearch } from "actions/Students";
import { getClassStudentsByType } from "actions/Students";
import { searchClassStudentsByType } from "actions/Students";

const TeacherClassStudentList = () => {
    const { teacherClassId } = useParams();
    const [allStudents, setAllStudents] = useState(null);
    const [isAllStudents, setIsAllStudents] = useState(false);
    const [isLoadingStudents, setLoadingStudents] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPge, SetItemsPerPage] = useState(10);
    const [skip, setSkip] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [currentStart, setCurrentStart] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [payType, setPayType] = useState(null);

    const getStudents = async (itemsPerPge, skip) => {
        if (payType !== null) {
            const students = await getClassStudentsByType(itemsPerPge, skip, teacherClassId, payType);
            setPageCount(students.data.pageCount);
            setAllStudents(students.data.data);
            if (students.data.data.length > 0) {
                setIsAllStudents(true);
            }
        } else {
            const students = await getClassStudentsByLimit(itemsPerPge, skip, teacherClassId);
            setPageCount(students.data.pageCount);
            setAllStudents(students.data.data);
            if (students.data.data.length > 0) {
                setIsAllStudents(true);
            }
        }
    }

    const fetchData = async () => {
        try {
            await getStudents(itemsPerPge, skip);
            setLoadingStudents(false);
        } catch (error) {
            setLoadingStudents(false);
            setIsAllStudents(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const searchCategory = async (searchQuery) => {
        if (payType !== null) {
            const searched = await searchClassStudentsByType(itemsPerPge, skip, searchQuery, teacherClassId, payType);
            setPageCount(searched.data.pageCount)
            setAllStudents(searched.data.data);
        } else {
        const searched = await getClassStudentsBySearch(itemsPerPge, skip, searchQuery, teacherClassId);
        setPageCount(searched.data.pageCount);
        setAllStudents(searched.data.data);
        }
    };

    const filterBySearch = async (itemsPerPge, skip, searchQuery) => {
        if (payType !== null) {
            const searched = await searchClassStudentsByType(itemsPerPge, skip, searchQuery, teacherClassId, payType);
            setPageCount(searched.data.pageCount)
            setAllStudents(searched.data.data);
            setCurrentPage(skip + 1);
        } else {
            const searched = await getClassStudentsBySearch(itemsPerPge, skip, searchQuery, teacherClassId);
            setPageCount(searched.data.pageCount)
            setAllStudents(searched.data.data);
            setCurrentPage(skip + 1);
        }
    }

    const handlePagination = async (pageNumber) => {
        if (searchQuery !== " ") {
            await filterBySearch(itemsPerPge, pageNumber - 1, searchQuery);
        } else {
            await fetchData(itemsPerPge, pageNumber - 1);
            setCurrentPage(pageNumber);
        }
    }

    const getPayType = async (payType) => {
        setPayType(payType);
        const students = await getClassStudentsByType(itemsPerPge, skip, teacherClassId, payType);
        setPageCount(students.data.pageCount);
        setAllStudents(students.data.data);
        if (students.data.data.length > 0) {
            setIsAllStudents(true);
        }
    }

    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                {isLoadingStudents ? (
                    <Card style={{}}>
                        <Spinner className="m-10">Loading...</Spinner>
                    </Card>
                ) : !isAllStudents ? (
                    <Card className="text-center" style={{ padding: "1rem" }}>
                        <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" />
                    </Card>
                ) : (
                    <Card className="shadow" style={{ padding: "1rem" }}>
                        <CardHeader className="border-0">
                            <Row>
                                <Col>
                                    <h3 className="mb-0">Students</h3>
                                </Col>
                                <Col className="justify-content-end" md="8" lg="4">
                                    <InputGroup className="mb-3">
                                        <Input
                                            type="text"
                                            placeholder="Search by full name"
                                            value={searchQuery}
                                            onChange={(e) => {
                                                setSearchQuery(e.target.value);
                                                setCurrentPage(1);
                                                setCurrentStart(0);
                                                searchCategory(e.target.value);
                                            }}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <div className="payEdtiBtnsSection mt-3">
                                    <div className="payTypeEditBtn">
                                        <Button
                                            color={
                                                payType === "FULL"
                                                    ? "primary"
                                                    : "default"
                                            }
                                            type="button"
                                            onClick={() => getPayType('FULL')}
                                            disabled={payType === "FULL"}
                                        >
                                            Full
                                        </Button>
                                        <Button
                                            color={
                                                payType === "HALF_FREE"
                                                    ? "primary"
                                                    : "default"
                                            }
                                            type="button"
                                            onClick={() => getPayType('HALF_FREE')}
                                            disabled={payType === "HALF_FREE"}
                                        >
                                            Half
                                        </Button>
                                        <Button
                                            color={
                                                payType === "FULL_FREE"
                                                    ? "primary"
                                                    : "default"
                                            }
                                            type="button"
                                            onClick={() => getPayType('FULL_FREE')}
                                            disabled={payType === "FULL_FREE"}
                                        >
                                            Free
                                        </Button>
                                    </div>
                                </div>
                            </Row>
                        </CardHeader>
                        <Table className="align-items-center" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Reg. No</th>
                                    <th scope="col">Full Name</th>
                                    <th scope="col">Contact Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allStudents?.map((student, index) => (
                                    <tr key={index}>
                                        <td>{student.user.registration_number}</td>
                                        <td>{student.user.full_name}</td>
                                        <td>{student.user.phone_number}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div style={{ paddingTop: "10px" }}>
                            {pageCount > 1 ?
                                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                                    currentStart={currentStart}
                                    setCurrentStart={setCurrentStart}></Paginations>
                                : null}
                        </div>
                    </Card>
                )}
            </div >
        </>
    );
};

export default TeacherClassStudentList;
