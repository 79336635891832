// reactstrap components
import {
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { useParams } from "react-router-dom";
import { getPaymentsByTeacherId } from "actions/Teachers";
import { downloadPaymentByTeacherIdCsvFile } from "actions/Teachers";
import { getBalanceByTeacherId } from "actions/Teachers";
import { getUser } from "actions/Auth";
import { makeTeacherPayment } from "actions/Teachers";
import { HiDownload } from "react-icons/hi";

const TeacherPaymentDetails = () => {
  const { teacherId, full_name } = useParams();
  const [teacherClassPayments, setTeacherClassPayments] = useState(null);
  const [isTeacherClassPayments, setIsTeacherClassPayments] = useState(false);
  const [isLoadingStudents, setLoadingStudents] = useState(true);
  const [balance, setBalance] = useState(0);
  const [senderId, setSenderId] = useState(null);

  const fetchData = async () => {
    try {
      const user = await getUser();
      setSenderId(user.id);
      const paymentDetails = await getPaymentsByTeacherId(teacherId);
      const balance = await getBalanceByTeacherId(teacherId);
      setTeacherClassPayments(paymentDetails.data);
      setBalance(balance.payment_balance);
      setLoadingStudents(false);
      setIsTeacherClassPayments(true);
    } catch (error) {
      setLoadingStudents(false);
      setIsTeacherClassPayments(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        {isLoadingStudents ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isTeacherClassPayments ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data Available" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="headerSection border-0">
              <div md="6">
                <h3 className="mb-2">Payment Records</h3>
                {balance ?
                  <h3>Payment Balance: Rs.{balance ? balance : null}</h3> :
                  <h3>No payments available yet</h3>}
              </div>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Teacher</th>
                  <th scope="col">Paid By</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                {teacherClassPayments?.map((teacherClassPayment, index) => (
                  <tr key={index}>
                    <td>{teacherClassPayment.created_at.split("T")[0]}</td>
                    <td>
                      {
                        teacherClassPayment.teacher.full_name
                      }
                    </td>
                    <td>
                      {teacherClassPayment.sender.full_name}
                    </td>
                    <td>
                      {teacherClassPayment.amount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}
      </div>
    </>
  );
};

export default TeacherPaymentDetails;
