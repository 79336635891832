// reactstrap components
import {
  Table,
  Card,
  CardHeader,
  Button,
  Spinner,
} from "reactstrap";
// core components
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { downloadPaymentByTeacherIdCsvFile } from "actions/Teachers";
import { getBalanceByTeacherId } from "actions/Teachers";
import { getUser } from "actions/Auth";
import { getPaymentsByTeacherIdAndLimit } from "actions/Teachers";
import Paginations from "components/Pagination/Paginations";
import "../../../assets/css/teacher-styles.css";
import { HiDownload } from "react-icons/hi";

const TeacherIncomeDetails = () => {
  const [teacherPayments, setTeacherPayments] = useState(null);
  const [isTeacherPayments, setIsTeacherPayments] = useState(false);
  const [isLoadingDetails, setLoadingDetails] = useState(true);
  const [balance, setBalance] = useState(0);
  const [user, setUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, setItemsPerPage] = useState(10);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);

  const getTeacherIncomeByLimit = async (id, itemsPerPge, skip) => {
    const teacherPayments = await getPaymentsByTeacherIdAndLimit(id, itemsPerPge, skip);
    setPageCount(teacherPayments.pageCount);
    setTeacherPayments(teacherPayments.data);
  }


  const fetchData = async () => {
    try {
      const user = await getUser();
      setUser(user);
      await getTeacherIncomeByLimit(user.id, itemsPerPge, skip);
      const balance = await getBalanceByTeacherId(user.id);
      setBalance(balance.payment_balance);
      setLoadingDetails(false);
      setIsTeacherPayments(true);
    } catch (error) {
      setLoadingDetails(false);
      setIsTeacherPayments(false);
    }
  };

  const downloadBlob = async () => {
    await downloadPaymentByTeacherIdCsvFile(user.id, user.full_name);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePagination = async (pageNumber) => {
    await getTeacherIncomeByLimit(user.id, itemsPerPge, pageNumber - 1);
    setCurrentPage(pageNumber);
  }

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        {isLoadingDetails ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isTeacherPayments ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="border-0">
              <div className="teacherPaymentHeader">
                <h3 className="mb-0">Payment Records</h3>
                <div className="teacherPaymentBtn">
                  <h3>Payment Balance: Rs.{balance ? balance : 0}</h3>
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => downloadBlob()}
                    className="exportPaymentBtn"
                  >
                    <HiDownload /> Export
                  </Button>
                </div>
              </div>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Teacher</th>
                  <th scope="col">Paid By</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                {teacherPayments?.map((teacherPayment, index) => (
                  <tr key={index}>
                    <td>{teacherPayment.created_at.split("T")[0]}</td>
                    <td>
                      {
                        teacherPayment.teacher.full_name
                      }
                    </td>
                    <td>
                      {teacherPayment.sender.full_name}
                    </td>
                    <td>
                      {teacherPayment.amount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ paddingTop: "10px" }}>
              {pageCount > 1 ?
                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                  currentStart={currentStart}
                  setCurrentStart={setCurrentStart}></Paginations>
                : null}
            </div>
          </Card>
        )}
      </div>
    </>
  );
};

export default TeacherIncomeDetails;
