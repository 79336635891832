import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import TeacherLayout from "layouts/Teacher.js";
import LandingPage from "views/pages/LandingPage";
import ClassAssistantLayout from "layouts/ClassAssistant";
import HighLevelAdminLayout from "layouts/HighLevelAdmin";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/admin/*" element={<AdminLayout />} />
      <Route path="/high-level-admin/*" element={<HighLevelAdminLayout />} />
      <Route path="/teacher/*" element={<TeacherLayout />} />
      <Route path="/classassistant/*" element={<ClassAssistantLayout />} />
      <Route path="/auth/*" element={<AuthLayout />} />
      <Route path="*" element={<AuthLayout/>} />
    </Routes>
  </BrowserRouter>
);
