// reactstrap components
import {
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
  InputGroup,
} from "reactstrap";
// core components
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { findByUserIdForAdmin } from "actions/Students";
import { Link, useParams } from "react-router-dom";
import { editStudentClass } from "actions/Students";
import { getAllActiveGrades } from "actions/Grades";
import { findTeachersByGradeId } from "actions/Teachers";
import { findAllSubjectsByTeacherIdAndGradeId } from "actions/Subjects";
import { enrollStudent } from "actions/Students";

const ClassStudentDetails = () => {
  const Status = {
    ACTIVE: "ACTIVE",
    BANNED: "BANNED",
    INACTIVE: "INACTIVE",
    PENDING: "PENDING",
    DEACTIVATED: "DEACTIVATED",
  };

  const { id } = useParams();
  const [enrolledClasses, setEnrolledClasses] = useState(null);
  const [isEnrolledClasses, setIsEnrolledClasses] = useState(false);

  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [editModal, setEditmodal] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [isLoadingStudents, setLoadingStudents] = useState(true);
  const [isEditedInput, setEditedInput] = useState(false);
  const [isDisabledEditButton, setDisabledEditButton] = useState(true);
  const [allGrades, setAllGrades] = useState(null);
  const [gradeDropdownOpen, setGradeDropdownOpen] = useState(false);
  const [selectedGradeForStudent, setSelectedGradeForStudent] = useState(null);
  const [allTeachersByGrade, setAllTeachersByGrade] = useState(null);
  const [teacherDropdownOpen, setTeacherDropdownOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [allSubjects, setAllSubjects] = useState(null);
  const [subjectDropdownOpen, setSubjectDropdownOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [isInsertButtonDisabled, setinsertButtonDisabled] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, SetItemsPerPage] = useState(10);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredStudents, setFilteredStudents] = useState(enrolledClasses);
  const [initialStudent, setInitialStudent] = useState({
    id: null,
    registration_number: "",
    full_name: "",
    email: "",
    phone_number: "",
    password: "",
    status: "",
  });

  const [isEditError, setEditError] = useState({
    full_name: "",
    phone_number: "",
  });


  const [isData, setIsData] = useState({
    id: "",
    enrolled_classes: [],
  });

  const fetchData = async () => {
    try {
      const grades = await getAllActiveGrades();
      setAllGrades(grades);
      const enrolledClasses = await findByUserIdForAdmin(id);
      setEnrolledClasses(enrolledClasses);
      setFilteredStudents(null);
      setLoadingStudents(false);
      if (enrolledClasses.length > 0) {
        setIsEnrolledClasses(true);
      }
      setIsData({ ...isData, id: +id });
    } catch (error) {
      setLoadingStudents(false);
      setIsEnrolledClasses(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getTeachers = async (gradeId) => {
    const teachersByGrade = await findTeachersByGradeId(gradeId);
    setAllTeachersByGrade(teachersByGrade);
  }

  const getSubjects = async (gradeId, teacherId) => {
    const subjectsByGradeAndTeacher = await findAllSubjectsByTeacherIdAndGradeId(gradeId, teacherId);
    setAllSubjects(subjectsByGradeAndTeacher);
  }

  const [selectedClasses, setSelectedClasses] = useState([]);

  const updateSelectedClasses = (grade, teacher, subject) => {
    setSelectedClasses(prevSelectedClasses => [
      ...prevSelectedClasses,
      { grade: grade, teacher: teacher, subject: subject }
    ]);
  };
  
  const removeSelectedClass = (indexToRemove) => {
    setSelectedClasses(prevSelectedClasses => {
      // Filter out the item at the specified index
      return prevSelectedClasses.filter((_, index) => index !== indexToRemove);
    });
  };
  
  const addClass = async () => {
    const newEnrolledClass = {
      teacherId: selectedTeacher.id,
      gradeId: selectedGradeForStudent.id,
      subjectId: selectedSubject.id
    };
  
    setIsData(prevState => {
      // Check if the class is already enrolled
      const isAlreadyEnrolled = prevState.enrolled_classes.some(
        enrolledClass => 
          enrolledClass.teacherId === newEnrolledClass.teacherId &&
          enrolledClass.gradeId === newEnrolledClass.gradeId &&
          enrolledClass.subjectId === newEnrolledClass.subjectId
      );
  
      if (isAlreadyEnrolled) {
        setFailed(true);
        setErrorMessage('This student is already enrolled in this class.');
        return prevState; // No changes to state
      }
  
      const updatedEnrolledClasses = [...prevState.enrolled_classes, newEnrolledClass];
      return {
        ...prevState,
        enrolled_classes: updatedEnrolledClasses
      };
    });
  
    // Only update selected classes if the class was successfully enrolled
    setSelectedClasses(prevSelectedClasses => {
      const isAlreadySelected = prevSelectedClasses.some(
        selectedClass =>
          selectedClass.grade === selectedGradeForStudent.name &&
          selectedClass.teacher === selectedTeacher.full_name &&
          selectedClass.subject === selectedSubject.name
      ); 
      if (!isAlreadySelected) {
        return [
          ...prevSelectedClasses,
          { grade: selectedGradeForStudent.name, teacher: selectedTeacher.full_name, subject: selectedSubject.name }
        ];
      }  
      return prevSelectedClasses; // No changes to selected classes
    });
  };

  const removeEnrolledClass = (enrolledClassIdToRemove) => {
    setIsData(prevState => {
      const updatedEnrolledClasses = prevState.enrolled_classes.slice(); // Create a copy of enrolled_classes array
      updatedEnrolledClasses.splice(enrolledClassIdToRemove, 1); // Remove the enrolled class at the specified index
      return {
        ...prevState,
        enrolled_classes: updatedEnrolledClasses
      };
    });
    removeSelectedClass(enrolledClassIdToRemove);

  };
  useEffect(() => {
  }, [isData]);

  const insertNewEnrollment = async () => {
    setinsertButtonDisabled(true);
    const response = await enrollStudent(isData);
    if (response.success === true) {
      fetchData();
      setSuccessMessage("Succesfully Enrolled the Student!");
      setSuccess(true);
      setSelectedGradeForStudent(null);
      setSelectedTeacher(null);
      setSelectedSubject(null);
      setSelectedClasses([]);
      // setinsertButtonDisabled(false);
    } else {
      setErrorMessage(response.message);
      // setinsertButtonDisabled(false);
      setFailed(true);
    }
  };

  const searchCategory = (searchQuery) => {
    setFilteredStudents(
      enrolledClasses.filter((enrolledClass) =>
        enrolledClass.teacher_class.teacher.full_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
  };

  const handleEditClick = (enrolledClass) => {
    setSelectedStudent(enrolledClass);
    setInitialStudent(enrolledClass);
    setEditmodal(true);
  };

  const editStudentClassDetails = async () => {
    const response = await editStudentClass(selectedStudent);
    if (response.success === true) {
      setSuccessMessage(response.message);
      setSuccess(true);
      fetchData();
      setEditmodal(false);
    } else {
      setErrorMessage(response.message);
      setFailed(true);
    }
  }

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        {isLoadingStudents ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isEnrolledClasses ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data Available" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="border-0">
              <Row>
                <Col>
                  <h3 className="mb-0">Entrolled Classes</h3>
                </Col>
                <Col className="justify-content-end" md="4">
                  <InputGroup className="mb-3">
                    <Input
                      type="text"
                      placeholder="Search by teacher"
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        searchCategory(e.target.value);
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Teacher</th>
                  <th scope="col">Grade</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Status</th>
                  <th scope="col" style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredStudents
                  ? filteredStudents?.map((enrolledClass, index) => (
                    <tr key={index}>
                      <td>{enrolledClass.teacher_class.teacher.full_name}</td>
                      <td>{enrolledClass.teacher_class.grade.name}</td>
                      <td>{enrolledClass.teacher_class.subject.name}</td>
                      <td>{enrolledClass.status ? "Active" : "Inactive"}</td>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          color="secondary"
                          type="button"
                          tag={Link}
                          to={`/high-level-admin/students/payment-records/${enrolledClass.id}`}
                        >
                          Payment Records
                        </Button>
                      </td>
                    </tr>
                  ))
                  : enrolledClasses?.map((enrolledClass, index) => (
                    <tr key={index}>
                      <td>{enrolledClass.teacher_class.teacher.full_name}</td>
                      <td>{enrolledClass.teacher_class.grade.name}</td>
                      <td>{enrolledClass.teacher_class.subject.name}</td>
                      <td>{enrolledClass.status ? "Active" : "Inactive"}</td>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          color="primary"
                          type="button"
                          tag={Link}
                          to={`/high-level-admin/students/payment-records/${enrolledClass.id}`}
                        >
                          Payment Records
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card>
        )}
      </div>
    </>
  );
};

export default ClassStudentDetails;
