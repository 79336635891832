import {
    Card,
    CardHeader,
    Row,
    Col,
    CardBody,
    CardFooter,
} from "reactstrap";
import React, { useEffect, useState } from 'react';
import "../../assets/css/qr-card-styles.css";
import Barcode from "react-barcode";
import Logo from '../../assets/img/brand/ebis-logo.png';
import BlankImage from '../../assets/img/brand/blank_profile_pic.png';


const StudentIdCard = ({ student }) => {
    return (
        <Card
            className="qRCard"
            style={{
                position: 'relative',
                margin: 'auto',
                color: 'white',
                borderRadius: '20px',
                textAlign: 'center',
                padding: '20px',
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '120px',
                    // backgroundColor: '#2F4FA2',
                    zIndex: 0,
                }}
                className="shape"
            ></div>

            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '10px',
                    backgroundColor: '#2F4FA2',
                    zIndex: 0,
                }}
            ></div>
            <CardHeader
                className="idCardHeader"
                style={{ borderBottom: 'none', backgroundColor: 'transparent', position: 'relative', zIndex: 10 }}
            >
                <img src={Logo} alt="Institute Logo" width={60} crossOrigin="anonymous" />
                <p className="instName">
                        ebis Higher Educational Institute <br/>
                        Kottawa
                    </p>
            </CardHeader>
            <CardBody className="qrBody" style={{ zIndex: 1, position: 'relative' }}>
                <img src={
                    student.image_url
                        ? student.image_url
                        : BlankImage
                } className="stuImageId" width={90} borderRadius={5} crossOrigin="Access-Control-Allow-Origin" />
                <Row className="studentNameSection">
                    <p className="regNumber">
                        {student.registration_number}
                    </p>
                    <p className="title">
                        {student.full_name.toUpperCase()}
                    </p>
                </Row>
            </CardBody>
            <Barcode
                className="barCodeId"
                value={student.barcode}
                height={40}
                fontSize={12}
                style={{ position: 'relative', zIndex: 1 }}
            />
        </Card>

    );
};

export default StudentIdCard;
