import {
  Table,
  Card,
  CardHeader,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { getLastYearClassFees } from "actions/Students";
import { useParams } from "react-router-dom";

const ClassStudentPaymentRecords = () => {
  const { id } = useParams();
  const [enrolledClassPayment, setEnrolledClasses] = useState(null);
  const [isPayments, setPayments] = useState(false);
  const [isLoadingStudentPayments, setLoadingStudentsPayments] = useState(true);

  const fetchData = async () => {
    try {
      const enrolledClassPayment = await getLastYearClassFees(id);
      setEnrolledClasses(enrolledClassPayment);
      setLoadingStudentsPayments(false);
      if (enrolledClassPayment.length > 0) {
        setPayments(true);
      }
    } catch (error) {
      setLoadingStudentsPayments(false);
      setPayments(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        {isLoadingStudentPayments ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isPayments ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data Available" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="border-0">
              <Row>
                <Col>
                  <h3 className="mb-0">Payment Records</h3>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Payment Month</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Scholarship</th>
                </tr>
              </thead>
              <tbody>
                {enrolledClassPayment?.map((enrolledClassPayment, index) => (
                  <tr key={index}>
                    <td>{enrolledClassPayment.created_at.split("T")[0]}</td>
                    <td>
                      {enrolledClassPayment.year}{"-"}{enrolledClassPayment.month.toString().padStart(2, "0")}
                    </td>
                    <td>{enrolledClassPayment.class_user.teacher_class.price}</td>
                    <td>
                      {enrolledClassPayment.isScholarship ? "Active" : "Inactive"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}
      </div>
    </>
  );
};

export default ClassStudentPaymentRecords;
