import React, { useState } from "react";
// reactstrap components
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const Paginations = ({ totalPages, handlePagination, currentPage, currentStart, setCurrentStart }) => {
    const paginationNumber = [];
    const maxVisiblePages = 5;

    for (let i = 1; i <= totalPages; i++) {
        paginationNumber.push(i);
    }

    const handleNext = () => {
        if (currentStart + maxVisiblePages < totalPages) {
            setCurrentStart(currentStart + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStart > 0) {
            setCurrentStart(currentStart - 1);
        }
    };

    const end = currentStart + maxVisiblePages;
    const visiblePaginationNumbers = paginationNumber.slice(currentStart, end);
    const centerNumber = (pageNumber) => {
        const halfVisiblePages = Math.floor((visiblePaginationNumbers.length / 2)+1);
        let newCurrentStart = pageNumber - halfVisiblePages;
        if (newCurrentStart < 0) {
            newCurrentStart = 0;
        } else if (newCurrentStart > totalPages - visiblePaginationNumbers.length) {
            newCurrentStart = totalPages - visiblePaginationNumbers.length;
        }
        setCurrentStart(newCurrentStart);
    };

    return (
        <>
            <nav aria-label="...">
                <Pagination className="pagination justify-content-center"
                    listClassName="justify-content-center">
                    <PaginationItem disabled={currentStart === 0}>
                        <PaginationLink
                            href="#"
                            onClick={e => { e.preventDefault(); handlePrevious(); }}
                            tabIndex="-1"
                        >
                            <i className="fa fa-angle-left" />
                            <span className="sr-only">Previous</span>
                        </PaginationLink>
                    </PaginationItem>
                    {visiblePaginationNumbers.map((data) => (
                        <PaginationItem key={data} className={currentPage === data ? 'active' : ''}>
                            <PaginationLink onClick={() => [handlePagination(data), centerNumber(data)]}>
                                {data}
                            </PaginationLink>
                        </PaginationItem>
                    ))}
                    <PaginationItem disabled={end >= totalPages}>
                        <PaginationLink href="#" onClick={e => { e.preventDefault(); handleNext(); }}>
                            <i className="fa fa-angle-right" />
                            <span className="sr-only">Next</span>
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
            </nav>
        </>
    );
}

export default Paginations;
