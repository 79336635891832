import {
  FormGroup,
  Form,
  Input,
  InputGroup,
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { addNewSubject } from "actions/Subjects";
import { editSubject } from "actions/Subjects";
import { getSubjectsBySearch } from "actions/Subjects";
import Paginations from "components/Pagination/Paginations";
import { getSubjectsByLimit } from "actions/Subjects";
import { BiEdit } from "react-icons/bi";

const Subjects = () => {
  const [allSubjects, setAllSubjects] = useState(null);
  const [isSubjects, setIsSubjects] = useState(false);
  const [isLoadingSubjects, setLoadingSubjects] = useState(true);
  const [editModal, setEditmodal] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState({
    id: null,
    name: "",
    status: "",
  });
  const [isData, setData] = useState({
    subjectName: "",
    status: "",
  });
  const [errors, setErrors] = useState({});
  const [editErrors, setEditErrors] = useState({});
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isEdited, setEdit] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, SetItemsPerPage] = useState(10);
  const [skip, setSkip]= useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [initialSubject, setInitialSubject] = useState({
    id: null,
    name: "",
    status: "",
  });

  const fetchData = async (itemsPerPge, skip) => {
    try {
      const Subjects = await getSubjectsByLimit(itemsPerPge, skip);
    setPageCount(Subjects.data.pageCount);
      setAllSubjects(Subjects.data.data);
      setLoadingSubjects(false);
      setIsSubjects(true);
    } catch (error) {
      setLoadingSubjects(false);
      setIsSubjects(false);
    }
  };

  useEffect(() => {
    fetchData(itemsPerPge, skip);
  }, []);

  const searchSubject = async (searchQuery) => {
    const searched = await getSubjectsBySearch(itemsPerPge, skip, searchQuery);
    setPageCount(searched.data.pageCount);
    setAllSubjects(searched.data.data);
  };

  const filterBySearch = async (itemsPerPge, skip, searchQuery) => {
    const searched = await getSubjectsBySearch(itemsPerPge, skip, searchQuery);
    setPageCount(searched.data.pageCount);
    setAllSubjects(searched.data.data);
    setCurrentPage(skip + 1);
  }

  const handlePagination = async (pageNumber) => {
    if (searchQuery !== " ") {
      await filterBySearch(itemsPerPge, pageNumber - 1, searchQuery);
    } else {
      const features = await fetchData(itemsPerPge, pageNumber - 1);
      setCurrentPage(pageNumber);
    }
  }

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        {isLoadingSubjects ? (
          <Card>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isSubjects ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage"/>
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "2rem" }}>
            <Row>
              <Col>
                <h3 className="mb-0">Subjects</h3>
              </Col>
              <Col className="justify-content-end" md="4">
                <InputGroup className="mb-3">
                  <Input
                    type="text"
                    placeholder="Search by subject"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      setCurrentPage(1);
                      setCurrentStart(0);
                      searchSubject(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {allSubjects?.map((subject, index) => (
                      <tr key={index}>
                        <td>{subject.name}</td>
                        <td>{subject.status ? "Active" : "Inactive"}</td>
                      </tr>
                    ))}
              </tbody>
            </Table>  
            <div style={{paddingTop: "10px"}}>
          {pageCount > 1 ?
              <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                currentStart={currentStart}
                setCurrentStart={setCurrentStart}></Paginations>
              : null}
          </div>
          </Card>
        )}
      </div>
    </>
  );
};

export default Subjects;
