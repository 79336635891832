import axios from "axios";
const apiUrl = "https://ebis.ardillalabs.cloud/api"; 

export const addNewtransaction = async (addTransactionFormData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.post(
          `${apiUrl}/cashier-account/create-cashier-account`,
          addTransactionFormData,
          config
        );
  
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
            reject({
                response: {
                    message: err.response.data.message,
                    status: err.code,
                    statusText: err.code,            },
                });
        }
      }
    });
  };

export const getAllTransactions = async (dateOrMonth, skip, itemsPerPge) => {
return new Promise(async (resolve, reject) => {
    try {
    const config = {
        headers: {
        "Content-Type": "application/json",
        },
        withCredentials: true,
    };

    const response = await axios.get(
        `${apiUrl}/cashier-account?status=true&dateOrMonth=${dateOrMonth}&limit=${itemsPerPge}&skip=${skip}`,
        config
    );

    if (response.status === 200) {
        resolve(response.data);
    } else {
        reject(response.data);
    }
    } catch (err) {
    if (err.message === "Network Error") {
        reject({
        response: {
            data: {
            message: "Network Error",
            status: err.code,
            statusText: err.code,
            },
        },
        });
    } else {
        reject({
            response: {
                message: "There is an error in retrieving transactions.",
                status: err.code,
                statusText: err.code,            },
            });
    }
    }
});
};

export const getDailyExpenceSum = async (dateOrMonth, skip, itemsPerPge) => {
return new Promise(async (resolve, reject) => {
    try {
    const config = {
        headers: {
        "Content-Type": "application/json",
        },
        withCredentials: true,
    };

    const response = await axios.get(
        `${apiUrl}/cashier-account/find-all-payments-balance?status=true&dateOrMonth=${dateOrMonth}`,
        config
    );

    if (response.status === 200) {
        resolve(response.data);
    } else {
        reject(response.data);
    }
    } catch (err) {
    if (err.message === "Network Error") {
        reject({
        response: {
            message: "Network Error",
            status: err.code,
            statusText: err.code
        },
        });
    } else {
        reject({
        response: {
            message: "There is an error in retrieving the transaction balance.",
            status: err.code,
            statusText: err.code,            
        },
        })
    }
    }
});
};

export const editTransaction = async (editTransactionFormData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };

        const response = await axios.put(
          `${apiUrl}/cashier-account/update-transaction-info`,
          editTransactionFormData,
          config
        );
  
        if (response.data.success === true) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject(err);
        }
      }
    });
  };

export const getTransactionSummary = async (dateOrMonth, skip, itemsPerPge) => {
  return new Promise(async (resolve, reject) => {
      try {
      const config = {
          headers: {
          "Content-Type": "application/json",
          },
          withCredentials: true,
      };

      const response = await axios.get(
          `${apiUrl}/cashier-account/find-all-payments?status=true&dateOrMonth=${dateOrMonth}&limit=${itemsPerPge}&skip=${skip}&type=credit`,
          config
      );
  
      if (response.status === 200) {
          resolve(response.data);
      } else {
          reject(response.data);
      }
      } catch (err) {
      if (err.message === "Network Error") {
          reject({
          response: {
              data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
              },
          },
          });
      } else {
          reject({
              response: {
                  message: "There is an error in retrieving transactions.",
                  status: err.code,
                  statusText: err.code,            },
              });
      }
      }
  });
  };

export const getIncomeAndExpenses = async (dateOrMonth) => {
  return new Promise(async (resolve, reject) => {
      try {
      const config = {
          headers: {
          "Content-Type": "application/json",
          },
          withCredentials: true,
      };
      const response = await axios.get(
          `${apiUrl}/class-fee/get-income-and-expenses/${dateOrMonth}`,
          config
      );
  
      if (response.status === 200) {
          resolve(response.data);
      } else {
          reject(response.data);
      }
      } catch (err) {
      if (err.message === "Network Error") {
          reject({
          response: {
              data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
              },
          },
          });
      } else {
          reject({
              response: {
                  message: "There is an error in retrieving transactions.",
                  status: err.code,
                  statusText: err.code,            },
              });
      }
      }
  });
  };

export const getTeacherTransactionDetails = async (dateOrMonth) => {
  return new Promise(async (resolve, reject) => {
    try {
    const config = {
        headers: {
        "Content-Type": "application/json",
        },
        withCredentials: true,
    };
    const response = await axios.get(
        `${apiUrl}/teacher-payment/all-teachers-payments/${dateOrMonth}`,
        config
    );

    if (response.status === 200) {
        resolve(response.data);
    } else {
        reject(response.data);
    }
    } catch (err) {
    if (err.message === "Network Error") {
        reject({
        response: {
            data: {
            message: "Network Error",
            status: err.code,
            statusText: err.code,
            },
        },
        });
    } else {
        reject({
            response: {
                message: "There is an error in retrieving transactions.",
                status: err.code,
                statusText: err.code,            },
            });
    }
    }
});
}

export const findAllClassFeesBalance = async (dateOrMonth) => {
  return new Promise(async (resolve, reject) => {
    try {
    const config = {
        headers: {
        "Content-Type": "application/json",
        },
        withCredentials: true,
    };
    const response = await axios.get(
        `${apiUrl}/cashier-account/find-all-class-fees-balance?status=true&dateOrMonth=${dateOrMonth}`,
        config
    );

    if (response.status === 200) {
        resolve(response.data);
    } else {
        reject(response.data);
    }
    } catch (err) {
    if (err.message === "Network Error") {
        reject({
        response: {
            data: {
            message: "Network Error",
            status: err.code,
            statusText: err.code,
            },
        },
        });
    } else {
        reject({
            response: {
                message: "There is an error in retrieving transactions.",
                status: err.code,
                statusText: err.code,            },
            });
    }
    }
});
}
