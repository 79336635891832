import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { useState, useEffect } from "react";
import { getUser } from "actions/Auth";
import { signOutSubmit } from "actions/Auth";
import { useNavigate } from 'react-router-dom';
import BlankPic from "../../assets/img/brand/blank_profile_pic.png";

const AdminNavbar = (props) => {

  const [user, setUser] = useState(null);
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const user = await getUser();
      setUser(user);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const logOut = async () => {
    const response = await signOutSubmit();
    if (response.success === true) {
      setSuccessMessage(response.message);
      setSuccess(true);
      navigate("/auth/login");
    } else {
      setErrorMessage(response.message);
      setFailed(true);
    }
  };
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-ebis text-uppercase d-none d-lg-inline-block" style={{ cursor: "none" }}
          >
            {props.brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={user?.image_url ? user.image_url : BlankPic}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm text-ebis font-weight-bold">
                      {user ? user.full_name : ""}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                {user?.roleName === 'admin' ? (
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                ) : user?.roleName === 'high-level-admin' ? (
                  <DropdownItem to="/high-level-admin/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                ) : (
                  <DropdownItem to="/teacher/teacher-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                )}
                <DropdownItem onClick={logOut}>
                  <i className="ni ni-user-run text-ebis" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
