// reactstrap components
import {
    Table,
    Card,
    CardHeader,
    Modal,
    Button,
    ModalBody,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Spinner,
  } from "reactstrap";
  import { useState, useEffect } from "react";
  import SecondaryHeader from "components/Headers/SecondaryHeader";
  import isEmail from "validator/lib/isEmail";
  import isMobilePhone from "validator/lib/isMobilePhone";
import { createClassAssistant } from "actions/ClassAsistants";
import { getAllClassAssistants } from "actions/ClassAsistants";
import { editClassAssistant } from "actions/ClassAsistants";
import { editAssistantPassword } from "actions/ClassAsistants";
import { BiEdit } from "react-icons/bi";
import { FaExpeditedssl } from "react-icons/fa";
  
  const ClassAssistants = () => {
    const Status = {
      ACTIVE: "ACTIVE",
      BANNED: "BANNED",
      INACTIVE: "INACTIVE",
      PENDING: "PENDING",
      DEACTIVATED: "DEACTIVATED",
    };
    const [allClassAssistants, setAllClassAssistants] = useState(null);
    const [isAllClassAssistants, setIsAllClassAssistants] = useState(false);  
    const [isSuccess, setSuccess] = useState(false);
    const [isFailed, setFailed] = useState(false);
    const [selectedClassAssistant, setSelectedClassAssistant] = useState(null);
    const [editModal, setEditmodal] = useState(false);
    const [editPasswordModal, setEditPasswordmodal] = useState(false);
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const [isInsertButtonDisabled, setinsertButtonDisabled] = useState(false);
    const [isSuccessMessage, setSuccessMessage] = useState("");
    const [isErrorMessage, setErrorMessage] = useState("");
    const [isLoadingClassAssistants, setLoadingClassAssistants] = useState(true);
    const [isEditedInput, setEditedInput] = useState(false);
    const [isDisabledEditButton, setDisabledEditButton] = useState(true);
  
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredClassAssistants, setFilteredClassAssistants] = useState(allClassAssistants);
    const [initialClassAssistant, setInitialClassAssistant] = useState({
      id: null,
      full_name: "",
      email: "",
      phone_number: "",
      password: "",
      status: "",
    });
    const [isData, setIsData] = useState({
      full_name: "",
      email: "",
      phone_number: "",
      password: "",
    });

    const [isError, setIsError] = useState({
      full_name: "",
      email: "",
      phone_number: "",
      password: "",
    });
  
    const [isEditError, setEditError] = useState({
      full_name: "",
      phone_number: "",
      password: "",
    });
  
    const [editPassword, setEditPassword] = useState({
      teacherId: null,
      newPassword: "",
      confirmPassword: "",
    });
  
    const [isEditPasswordError, setEditPasswordError] = useState({
      newPassword: "",
      confirmPassword: "",
    });
  
    const fetchData = async () => {
      try {
        const classAssistants = await getAllClassAssistants();
        setAllClassAssistants(classAssistants);
        setLoadingClassAssistants(false);
        setIsAllClassAssistants(true);
      } catch (error) {
        setLoadingClassAssistants(false);
        setIsAllClassAssistants(false);
      }
    };
  
    useEffect(() => {
      fetchData();
    }, []);
  
    const searchCategory = (searchQuery) => {
      setFilteredClassAssistants(
        allClassAssistants.filter((classAssistant) =>
          classAssistant.full_name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    };
  
    const handleInputChange = (event) => {
      setIsError({ ...isError, [event.target.name]: "" });
      setIsData({ ...isData, [event.target.name]: event.target.value });
    };
  
    // Validate input
    const isValidClassAssistant = (value) => {
      const errors = {};
      if (value.full_name.length < 6) {
        errors.full_name =
          "Class Assistant Full Name must be at least 6 characters long.";
      }
      if (!isEmail(value.email)) {
        errors.email = "Value must be a valid email.";
      }
      if (!isMobilePhone(value.phone_number)) {
        errors.phone_number = "Value must be a valid phone number.";
      }
      if (value.password.length < 5) {
        errors.password = "Password must be at least 5 characters long.";
      }
      if (Object.keys(errors).length > 0) {
        setIsError(errors);
        return false; // Exit early if there are errors
      } else {
        return true;
      }
    };
  
    const insertNewClassAssistant = async () => {
      if (isValidClassAssistant(isData)) {
        setinsertButtonDisabled(true);
        const response = await createClassAssistant(isData);
        if (response.success === true) {
          setSuccessMessage(response.message);
          setSuccess(true);
          setIsData({
            ...isData,
            full_name: "",
            email: "",
            phone_number: "",
            password: "",
          });
          fetchData();
          setinsertButtonDisabled(false);
        } else {
          setErrorMessage(response.message);
          setinsertButtonDisabled(false);
          setFailed(true);
        }
      }
    };
  
    const handleEditClick = (classAssistant) => {
      setSelectedClassAssistant(classAssistant);
      setInitialClassAssistant(classAssistant);
      setEditmodal(true);
    };
  
    const handleEditPasswordClick = (classAssistant) => {
      setSelectedClassAssistant(classAssistant);
      setEditPassword({
        ...editPassword,
        teacherId: classAssistant.id,
      });
      setEditPasswordmodal(true);
    };
  
    // Validate input
    const isValidEditClassAssistant = (value) => {
      const errors = {};
      if (value.full_name.length < 6) {
        errors.full_name =
          "Teacher Full Name must be at least 6 characters long.";
      }
      if (!isEmail(value.email)) {
        errors.email = "Value must be a valid email.";
      }
      if (!isMobilePhone(value.phone_number)) {
        errors.phone_number = "Value must be a valid phone number.";
      }
      if (Object.keys(errors).length > 0) {
        setEditError(errors);
        setDisabledEditButton(false);
        return false;
      } else {
        return true;
      }
    };
  
    function getChangedValues(selectedClassAssistant) {
      const changedValues = {};
      for (const key in selectedClassAssistant) {
        if (
          key !== "id" &&
          selectedClassAssistant.hasOwnProperty(key) &&
          selectedClassAssistant[key] !== initialClassAssistant[key]
        ) {
          changedValues[key] = selectedClassAssistant[key];
        }
  
        if (key === "id") {
          changedValues[key] = selectedClassAssistant[key];
        }
      }
      return changedValues;
    }
  
    const editClassAssistantDetails = async () => {
      const validityCheck = isValidEditClassAssistant(selectedClassAssistant);
      if (validityCheck === true) {
        setEditedInput(false);
        const data = getChangedValues(selectedClassAssistant);
        const body = JSON.stringify(data);
        const response = await editClassAssistant(body);
        if (response.success === true) {
          setSuccessMessage(response.message);
          setSuccess(true);
          await fetchData();
          setEditmodal(false);
        } else {
          setFailed(true);
        }
      }
    };
  
    // Validate input
    const isValidPassword = (value) => {
      const errors = {};
      if (value.newPassword.length < 6) {
        errors.newPassword = "Password must be at least 6 characters long.";
      }
      if (value.newPassword !== value.confirmPassword) {
        errors.confirmPassword = "Password and Confirm Password must be matched.";
      }
      if (Object.keys(errors).length > 0) {
        setEditPasswordError(errors);
        setDisabledEditButton(false);
        return false;
      } else {
        return true;
      }
    };
    const editClassAssistantPassword = async () => {
      if (isValidPassword(editPassword)) {
        const response = await editAssistantPassword(editPassword);
        if (response.success === true) {
          setEditPasswordmodal(false);
          setSuccessMessage(response.message);
          setSuccess(true);
          fetchData();
        } else {
          setErrorMessage(response.message);
          setFailed(true);
        }
      }
    };
    return (
      <>
        <SecondaryHeader />
        <div className="mt--5 container-fluid">
          <Modal
            className="modal-dialog-centered modal-success"
            isOpen={isSuccess}
            toggle={() => setSuccess(false)}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {isSuccessMessage}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setSuccess(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          </Modal>
          <Modal
            className="modal-dialog modal-danger"
            isOpen={isFailed}
            toggle={() => setFailed(false)}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {isErrorMessage}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setFailed(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          </Modal>
          <Row>
            <Col>
              <Card className="mb-4 shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col>
                      <h3 className="mb-0">Add New Class Assistant</h3>
                    </Col>
                  </Row>
                </CardHeader>
                {/* Insert Form */}
                <Form className="ml-4 mb-4 mr-4">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Input
                          id="fullName"
                          placeholder="Full Name"
                          type="text"
                          value={isData.full_name}
                          onChange={handleInputChange}
                          name="full_name"
                        />
                        {isError.full_name && (
                          <p className="text-danger">{isError.full_name}</p>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          id="email"
                          placeholder="Email Address"
                          type="test"
                          value={isData.email}
                          onChange={handleInputChange}
                          name="email"
                        />
                        {isError.email && (
                          <p className="text-danger">{isError.email}</p>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          id="phone_number"
                          placeholder="Phone Number"
                          type="text"
                          value={isData.phone_number}
                          onChange={handleInputChange}
                          name="phone_number"
                        />
                        {isError.phone_number && (
                          <p className="text-danger">{isError.phone_number}</p>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          id="password"
                          placeholder="Password"
                          type="password"
                          value={isData.password}
                          onChange={handleInputChange}
                          name="password"
                        />
                        {isError.password && (
                          <p className="text-danger">{isError.password}</p>
                        )}
                      </FormGroup>
                      <Button
                        color="primary"
                        type="button"
                        onClick={insertNewClassAssistant}
                        disabled={
                          !isData.full_name ||
                          !isData.email ||
                          !isData.phone_number ||
                          !isData.password ||
                          isInsertButtonDisabled
                        }
                      >
                        Insert
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
          {/* Display Tabel */}
          {isLoadingClassAssistants ? (
            <Card style={{}}>
              <Spinner className="m-10">Loading...</Spinner>
            </Card>
          ) : !isAllClassAssistants ? (
            <Card className="text-center" style={{ padding: "1rem" }}>
              {" "}
              Not Found Data
              <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage"/>
            </Card>
          ) : (
            <Card className="shadow" style={{ padding: "1rem" }}>
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0">Class Assistants</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Full Name</th>
                    <th scope="col">Contact Number</th>
                    <th scope="col">Email</th>
                    <th scope="col">Status</th>
                    <th colSpan={2} style={{textAlign: "center"}}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {allClassAssistants?.map((classAssistant, index) => (
                      <tr key={index}>
                        <td>{classAssistant.full_name}</td>
                        <td>{classAssistant.phone_number}</td>
                        <td>{classAssistant.email}</td>
                        <td>{classAssistant.status}</td>
                        <td style={{ textAlign: "center" }}>
                          <Button
                            color="primary"
                            type="button"
                            id="editClassAssistant"
                            onClick={() => handleEditClick(classAssistant)}
                          >
                           <BiEdit />
                      </Button>                       
                      <Button
                            color="primary"
                            type="button"
                            id="editPassword"
                            onClick={() => handleEditPasswordClick(classAssistant)}
                          >
                          <FaExpeditedssl />
                        </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card>
          )}
          {/* Edit teacher */}
          <Modal
            className="modal-dialog-centered"
            isOpen={editModal}
            toggle={() => [setEditmodal(false)]}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit class Assistant
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => [
                  setEditmodal(false),
                  setDisabledEditButton(true),
                  setEditError({
                    full_name: "",
                    phone_number: "",
                    password: "",
                  }),
                ]}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <Form>
                <Row>
                  <Col>
                    <FormGroup>
                      <Input
                        id="editFullName"
                        placeholder="Full Name"
                        type="text"
                        value={
                          selectedClassAssistant?.full_name
                            ? selectedClassAssistant?.full_name
                            : ""
                        }
                        name="full_name"
                        onChange={(e) => {
                          setSelectedClassAssistant({
                            ...selectedClassAssistant,
                            full_name: e.target.value,
                          });
                          setEditedInput(true);
                          setDisabledEditButton(false);
                          setEditError({ full_name: "" });
                        }}
                      />
                      {isEditError.full_name && (
                        <p className="text-danger">{isEditError.full_name}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Input
                        id="editPhoneNumber"
                        placeholder="Phone Number"
                        type="text"
                        value={
                          selectedClassAssistant?.phone_number
                            ? selectedClassAssistant?.phone_number
                            : ""
                        }
                        name="phone_number" // Added name attribute
                        onChange={(e) => {
                          setSelectedClassAssistant({
                            ...selectedClassAssistant,
                            phone_number: e.target.value,
                          });
                          setEditedInput(true);
                          setDisabledEditButton(false);
                          setEditError({ phone_number: "" });
                        }}
                      />
                      {isEditError.phone_number && (
                        <p className="text-danger">{isEditError.phone_number}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Dropdown
                        isOpen={statusDropdownOpen}
                        toggle={() => setStatusDropdownOpen(!statusDropdownOpen)}
                      >
                        <DropdownToggle caret>
                          {selectedClassAssistant
                            ? selectedClassAssistant.status
                            : "Select a Status"}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.entries(Status).map(([key, value]) => (
                            <DropdownItem
                              key={value}
                              value={value}
                              onClick={() => [
                                setSelectedClassAssistant({
                                  ...selectedClassAssistant,
                                  status: value,
                                }),
                                setEditedInput(true),
                                setDisabledEditButton(false),
                              ]}
                            >
                              {value}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
  
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => editClassAssistantDetails()}
                      disabled={!isEditedInput || isDisabledEditButton}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
          <Modal
            className="modal-dialog-centered"
            isOpen={editPasswordModal}
            toggle={() => [setEditPasswordmodal(false)]}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Password
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => [
                  setEditPasswordmodal(false),
                  setEditPasswordError({
                    newPassword: "",
                    confirmPassword: "",
                  }),
                ]}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <Form>
                <Row>
                  <Col>
                    <FormGroup>
                      <Input
                        id="editNewPassword"
                        placeholder="New Password"
                        type="password"
                        name="newPassword"
                        onChange={(e) => {
                          setEditPassword({
                            ...editPassword,
                            newPassword: e.target.value,
                          });
                          setEditedInput(true);
                          setDisabledEditButton(false);
                          setEditPasswordError({ newPassword: "" });
                        }}
                      />
                      {isEditPasswordError.newPassword && (
                        <p className="text-danger">
                          {isEditPasswordError.newPassword}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Input
                        id="editConfirmPassword"
                        placeholder="Confirm Password"
                        type="password"
                        name="confirmPassword" // Added name attribute
                        onChange={(e) => {
                          setEditPassword({
                            ...editPassword,
                            confirmPassword: e.target.value,
                          });
                          setEditedInput(true);
                          setDisabledEditButton(false);
                          setEditPasswordError({ confirmPassword: "" });
                        }}
                      />
                      {isEditPasswordError.confirmPassword && (
                        <p className="text-danger">
                          {isEditPasswordError.confirmPassword}
                        </p>
                      )}
                    </FormGroup>
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => editClassAssistantPassword()}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </>
    );
  };
  
  export default ClassAssistants;
  