import {
    Table,
    Card,
    CardHeader,
    Modal,
    Button,
    ModalBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Spinner,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import { FaExpeditedssl } from "react-icons/fa";
import { getAllAdmins } from "actions/Admins";
import { createAdmin } from "actions/Admins";
import { editAdminPassword } from "actions/Admins";

const Admin = () => {
    const [allAdmins, setAllAdmins] = useState(null);
    const [isAllAdmins, setIsAllAdmins] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isFailed, setFailed] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [editModal, setEditmodal] = useState(false);
    const [editPasswordModal, setEditPasswordmodal] = useState(false);
    const [isInsertButtonDisabled, setinsertButtonDisabled] = useState(false);
    const [isSuccessMessage, setSuccessMessage] = useState("");
    const [isErrorMessage, setErrorMessage] = useState("");
    const [isLoadingAdmins, setLoadingAdmins] = useState(true);
    const [isEditedInput, setEditedInput] = useState(false);
    const [isDisabledEditButton, setDisabledEditButton] = useState(true);

    const [initialAdmin, setInitialAdmin] = useState({
        id: null,
        full_name: "",
        email: "",
        phone_number: "",
        password: "",
        status: "",
    });
    const [isData, setIsData] = useState({
        full_name: "",
        email: "",
        phone_number: "",
        password: "",
    });

    const [isError, setIsError] = useState({
        full_name: "",
        email: "",
        phone_number: "",
        password: "",
    });

    const [editPassword, setEditPassword] = useState({
        teacherId: null,
        newPassword: "",
        confirmPassword: "",
    });

    const [isEditPasswordError, setEditPasswordError] = useState({
        newPassword: "",
        confirmPassword: "",
    });

    const fetchData = async () => {
        try {
            const admins = await getAllAdmins();
            setAllAdmins(admins);
            setLoadingAdmins(false);
            if (admins.length > 0) {
                setIsAllAdmins(true);
            }
        } catch (error) {
            setLoadingAdmins(false);
            setIsAllAdmins(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleInputChange = (event) => {
        setIsError({ ...isError, [event.target.name]: "" });
        setIsData({ ...isData, [event.target.name]: event.target.value });
    };

    // Validate input
    const isValidadmin = (value) => {
        const errors = {};
        if (value.full_name.length < 6) {
            errors.full_name =
                "Teacher Full Name must be at least 6 characters long.";
        }
        if (!isEmail(value.email)) {
            errors.email = "Value must be a valid email.";
        }
        if (!isMobilePhone(value.phone_number)) {
            errors.phone_number = "Value must be a valid phone number.";
        }
        if (value.password.length < 5) {
            errors.password = "Password must be at least 5 characters long.";
        }
        if (Object.keys(errors).length > 0) {
            setIsError(errors);
            return false; // Exit early if there are errors
        } else {
            return true;
        }
    };

    const insertNewadmin = async () => {
        if (isValidadmin(isData)) {
            setinsertButtonDisabled(true);
            const response = await createAdmin(isData);
            if (response.success === true) {
                setSuccessMessage(response.message);
                setSuccess(true);
                setIsData({
                    ...isData,
                    full_name: "",
                    email: "",
                    phone_number: "",
                    password: "",
                });
                fetchData();
                setinsertButtonDisabled(false);
            } else {
                setErrorMessage(response.message);
                setinsertButtonDisabled(false);
                setFailed(true);
            }
        }
    };

    const handleEditClick = (admin) => {
        setSelectedAdmin(admin);
        setInitialAdmin(admin);
        setEditmodal(true);
    };

    const handleEditPasswordClick = (admin) => {
        setSelectedAdmin(admin);
        setEditPassword({
            ...editPassword,
            teacherId: admin.id,
        });
        setEditPasswordmodal(true);
    };

    // Validate input
    const isValidPassword = (value) => {
        const errors = {};
        if (value.newPassword.length < 6) {
            errors.newPassword = "Password must be at least 6 characters long.";
        }
        if (value.newPassword !== value.confirmPassword) {
            errors.confirmPassword = "Password and Confirm Password must be matched.";
        }
        if (Object.keys(errors).length > 0) {
            setEditPasswordError(errors);
            setDisabledEditButton(false);
            return false;
        } else {
            return true;
        }
    };
    const editadminPassword = async () => {
        if (isValidPassword(editPassword)) {
            const response = await editAdminPassword(editPassword);
            if (response.success === true) {
                setEditPasswordmodal(false);
                setSuccessMessage(response.message);
                setSuccess(true);
                fetchData();
            } else {
                setErrorMessage(response.message);
                setFailed(true);
            }
        }
    };
    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                <Modal
                    className="modal-dialog-centered modal-success"
                    isOpen={isSuccess}
                    toggle={() => setSuccess(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isSuccessMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setSuccess(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Modal
                    className="modal-dialog modal-danger"
                    isOpen={isFailed}
                    toggle={() => setFailed(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isErrorMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setFailed(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Row>
                    <Col>
                        <Card className="mb-4 shadow">
                            <CardHeader className="border-0">
                                <Row>
                                    <Col>
                                        <h3 className="mb-0">Add New Admin</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            {/* Insert Form */}
                            <Form className="ml-4 mb-4 mr-4">
                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            <Input
                                                id="fullName"
                                                placeholder="Full Name"
                                                type="text"
                                                value={isData.full_name}
                                                onChange={handleInputChange}
                                                name="full_name"
                                            />
                                            {isError.full_name && (
                                                <p className="text-danger">{isError.full_name}</p>
                                            )}
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                id="email"
                                                placeholder="Email Address"
                                                type="test"
                                                value={isData.email}
                                                onChange={handleInputChange}
                                                name="email"
                                            />
                                            {isError.email && (
                                                <p className="text-danger">{isError.email}</p>
                                            )}
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                id="phone_number"
                                                placeholder="Phone Number"
                                                type="text"
                                                value={isData.phone_number}
                                                onChange={handleInputChange}
                                                name="phone_number"
                                            />
                                            {isError.phone_number && (
                                                <p className="text-danger">{isError.phone_number}</p>
                                            )}
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                id="password"
                                                placeholder="Password"
                                                type="password"
                                                value={isData.password}
                                                onChange={handleInputChange}
                                                name="password"
                                            />
                                            {isError.password && (
                                                <p className="text-danger">{isError.password}</p>
                                            )}
                                        </FormGroup>
                                        <Button
                                            color="primary"
                                            type="button"
                                            onClick={insertNewadmin}
                                            disabled={
                                                !isData.full_name ||
                                                !isData.email ||
                                                !isData.phone_number ||
                                                !isData.password ||
                                                isInsertButtonDisabled
                                            }
                                        >
                                            Insert
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Row>
                {/* Display Tabel */}
                {isLoadingAdmins ? (
                    <Card style={{}}>
                        <Spinner className="m-10">Loading...</Spinner>
                    </Card>
                ) : !isAllAdmins ? (
                    <Card className="text-center" style={{ padding: "1rem" }}>
                        <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" />
                    </Card>
                ) : (
                    <Card className="shadow" style={{ padding: "1rem" }}>
                        <CardHeader className="border-0">
                            <Row>
                                <Col>
                                    <h3 className="mb-0">Admins</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                        <Table className="align-items-center" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Full Name</th>
                                    <th scope="col">Contact Number</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allAdmins?.map((admin, index) => (
                                    <tr key={index}>
                                        <td>{admin.full_name}</td>
                                        <td>{admin.phone_number}</td>
                                        <td>{admin.email}</td>
                                        <td>
                                            <Button
                                                color="primary"
                                                type="button"
                                                id="editPassword"
                                                onClick={() => handleEditPasswordClick(admin)}
                                            >
                                                <FaExpeditedssl />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card>
                )}
                <Modal
                    className="modal-dialog-centered"
                    isOpen={editPasswordModal}
                    toggle={() => [setEditPasswordmodal(false)]}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Edit Password
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => [
                                setEditPasswordmodal(false),
                                setEditPasswordError({
                                    newPassword: "",
                                    confirmPassword: "",
                                }),
                            ]}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Input
                                            id="editNewPassword"
                                            placeholder="New Password"
                                            type="password"
                                            name="newPassword"
                                            onChange={(e) => {
                                                setEditPassword({
                                                    ...editPassword,
                                                    newPassword: e.target.value,
                                                });
                                                setEditedInput(true);
                                                setDisabledEditButton(false);
                                                setEditPasswordError({ newPassword: "" });
                                            }}
                                        />
                                        {isEditPasswordError.newPassword && (
                                            <p className="text-danger">
                                                {isEditPasswordError.newPassword}
                                            </p>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            id="editConfirmPassword"
                                            placeholder="Confirm Password"
                                            type="password"
                                            name="confirmPassword" // Added name attribute
                                            onChange={(e) => {
                                                setEditPassword({
                                                    ...editPassword,
                                                    confirmPassword: e.target.value,
                                                });
                                                setEditedInput(true);
                                                setDisabledEditButton(false);
                                                setEditPasswordError({ confirmPassword: "" });
                                            }}
                                        />
                                        {isEditPasswordError.confirmPassword && (
                                            <p className="text-danger">
                                                {isEditPasswordError.confirmPassword}
                                            </p>
                                        )}
                                    </FormGroup>
                                    <Button
                                        color="primary"
                                        type="button"
                                        onClick={() => editadminPassword()}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
};

export default Admin;
