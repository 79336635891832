import axios from "axios";
const apiUrl = "https://ebis.ardillalabs.cloud/api";
const authUrl = "https://ebis.ardillalabs.cloud/api/auth";

export const getAllTeachers = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/users/get-all-teachers`,
        config
      );

      if (response.status === 200) {
        resolve(response.data.teachers);
      } else {
        reject(response.data.teachers);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const createTeacher = async (createTeacherFormData, editorData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const responseUser = await axios.post(
        `${authUrl}/create-teacher`,
        createTeacherFormData,
        config
      );

      if (responseUser.data.success === true) {
        resolve(responseUser.data);
      } else {
        reject(responseUser.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        resolve(err.response.data);
      }
    }
  });
};

export const editTeacher = async (editTeacherFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.put(
        `${apiUrl}/users/update-teacher-info`,
        editTeacherFormData,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const editTeacherImage = async (userId, editTeacherImageData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      };
      const formData = new FormData();

      formData.append("userId", userId);
      formData.append("teacherImage", editTeacherImageData.teacherImage); // Append the image file

      const response = await axios.put(
        `${authUrl}/update-teacher-image`,
        formData,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const editUserTeacherPassword = async (editTeacherFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.put(
        `${authUrl}/create-new-password-for-teacher`,
        editTeacherFormData,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const findClassesByTeacherId = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/teacher-class/find-by-teacher-id/${id}`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const findClassFees = async (
  teacherClassId,
  isChecked,
  paymentType,
  monthOrDate,
  date,
  skip,
  limit
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const paidOrUnpaid = isChecked ? "paid" : "unpaid";
      const response = await axios.get(
        `${apiUrl}/class-fee/get-paid-or-unpaid-students?paid_or_unpaid=${paidOrUnpaid}&teacherClassId=${teacherClassId}&payment_type=${paymentType}&skip=${skip}&limit=${limit}&${monthOrDate}=${date}`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const downloadClassFeesCsvFile = async (
  teacherClassId,
  isChecked,
  paymentType,
  monthOrDate,
  date,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const paidOrUnpaid = isChecked ? "paid" : "unpaid";
      axios({
        method: 'get',
        url: `${apiUrl}/class-fee/get-paid-or-unpaid-students-csv?paid_or_unpaid=${paidOrUnpaid}&teacherClassId=${teacherClassId}&payment_type=${paymentType}&${monthOrDate}=${date}`,
        headers: {
          Accept: 'text/csv',
        },
      }).then((response) => {
        downloadBlob(response.data, `${paymentType}_${paidOrUnpaid}_${teacherClassId}_${date}.csv`);
      }).catch((err) => {
        reject({
          message: `This month(${date}), there were no data found.`
        });
      });
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

const downloadBlob = (blob, filename) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};


export const createTeacherClass = async (createTeacherClassFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const body = JSON.stringify(createTeacherClassFormData);

      const responseUser = await axios.post(
        `${apiUrl}/teacher-class/create-teacher-class`,
        body,
        config
      );

      if (responseUser.data.success === true) {
        resolve(responseUser.data);
      } else {
        reject(responseUser.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        resolve(err.response.data);
      }
    }
  });
}

export const editTeacherClassDetails = async (editTeacherClassFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.put(
        `${apiUrl}/teacher-class/update-teacher-class-info`,
        editTeacherClassFormData,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const findClassesByTeacherIdForAdmin = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/teacher-class/find-by-teacher-id-for-admin/${id}`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

// export const findAttendanceByClassForAdmin = async (id, subjectId, gradeId, date) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//         },
//         withCredentials: true,
//       };

//       const response = await axios.get(
//         `${apiUrl}/attendance/find-by-teacher-id/${id}/${subjectId}/${gradeId}/${date}`,
//         config
//       );

//       if (response.status === 200) {
//         resolve(response.data);
//       } else {
//         reject(response.data);
//       }
//     } catch (err) {
//       if (err.message === "Network Error") {
//         reject({
//           response: {
//             data: {
//               message: "Network Error",
//               status: err.code,
//               statusText: err.code,
//             },
//           },
//         });
//       } else {
//         reject(err);
//       }
//     }
//   });
// };

export const findTeachersByGradeId = async (gradeId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/users/find-teachers-by-grade-id/${gradeId}`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const getPaymentsByTeacherId = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/teacher-payment?teacherId=${id}`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const downloadPaymentByTeacherIdCsvFile = async (
  teacherId,
  full_name
) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios({
        method: 'get',
        url: `${apiUrl}/teacher-payment/download-csv-file?teacherId=${teacherId}`,
        responseType: 'blob',
        headers: {
          Accept: 'text/csv',
        },
      }).then((response) => {
        downloadBlob(response.data, `${full_name}.csv`);
      }).catch((err) => {
        reject({
          message: `There were no data found.`
        });
      });
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const getBalanceByTeacherId = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/teacher-payment/payment-balance/${id}`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const makeTeacherPayment = async (paymentFormDetails) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.post(
        `${apiUrl}/teacher-payment/create-teacher-payment`,
        paymentFormDetails,
        config
      );

      if (response.status === 201) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          message: "Network Error",
          status: err.code,
          statusText: err.code,
        });
      } else {
        reject({
          message: err.response.data.message[0],
          status: err.code,
          statusText: err.code,
        });
      }
    }
  });
};

export const getTeachersByLimit = async (limit, skip) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/users?skip=${skip}&limit=${limit}&role=teacher`,
        config
      );

      if (response.status === 200) {
        resolve(response);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
      }
    }
  });
}

export const getTeachersBySearch = async (limit, skip, search) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/users?skip=${skip}&limit=${limit}&role=teacher&search=${search}`,
        config
      );

      if (response.status === 200) {
        resolve(response);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
      }
    }
  });
}

export const getPaymentsByTeacherIdAndLimit = async (id, limit, skip) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/teacher-payment?teacherId=${id}&skip=${skip}&limit=${limit}`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const downloadMonthlyIncomeReport = async (
  monthYear,
  teacherId,
  teacherName,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios({
        method: 'get',
        url: `${apiUrl}/class-fee/download-teacher-monthly-income-csv-file/${monthYear}/${teacherId}`,
        responseType: 'blob',
        headers: {
          Accept: 'text/csv',
        },
      }).then((response) => {
        if (response.status === 200) {
          downloadReport(response.data, `${teacherName}-${monthYear}.csv`);
        }
      }).catch((err) => {
        reject({
          message: `This month(${monthYear}), there were no data found.`
        });
      });
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

const downloadReport = (blob, filename) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const findDailyClassFees = async (
  teacherClassId,
  paidOrUnpaid,
  paymentType,
  date,
  skip,
  limit
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/class-fee/get-paid-or-unpaid-students?paid_or_unpaid=${paidOrUnpaid}&teacherClassId=${teacherClassId}&payment_type=${paymentType}&skip=${skip}&limit=${limit}&date=${date}`,
        config
      );
      if (response.status === 200) {
        resolve(response.data.data);
      } else {
        reject(response.data.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const findAttendanceByClassForAdmin = async (id, date, skip, itemsPerPge, search) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/attendance/find-all-present?date=${date}&teacherClassId=${id}&limit=${itemsPerPge}&skip=${skip}&search=${search}`,
        config
      );
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          data: {
            message: err.response.data.message,
            status: err.code,
            statusText: err.code,
          },
        });
      }
    }
  });
};

export const findAbsentByClassForAdmin = async (id, date, skip, itemsPerPge, search) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/attendance/find-all-absent?date=${date}&teacherClassId=${id}&limit=${itemsPerPge}&skip=${skip}&search=${search}`,
        config
      );
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          data: {
            message: err.response.data.message,
            status: err.code,
            statusText: err.code,
          },
        });
      }
    }
  });
};

export const findAllAttendanceByClass = async (id, date) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/attendance/find-all-present?date=${date}&teacherClassId=${id}`,
        config
      );
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          data: {
            message: err.response.data.message,
            status: err.code,
            statusText: err.code,
          },
        });
      }
    }
  });
};

export const findAllAbsentByClass = async (id, date) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/attendance/find-all-absent?date=${date}&teacherClassId=${id}`,
        config
      );
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          data: {
            message: err.response.data.message,
            status: err.code,
            statusText: err.code,
          },
        });
      }
    }
  });
};

export const findAllClassFees = async (
  teacherClassId,
  isChecked,
  paymentType,
  monthOrDate,
  date,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const paidOrUnpaid = isChecked ? "paid" : "unpaid";
      const response = await axios.get(
        `${apiUrl}/class-fee/get-paid-or-unpaid-students?paid_or_unpaid=${paidOrUnpaid}&teacherClassId=${teacherClassId}&payment_type=${paymentType}&${monthOrDate}=${date}`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const findPaymentCancel = async (
  teacherId,
  date,
  skip,
  limit
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/cancel-class-fee?teacherId=${teacherId}&date=${date}&skip=${skip}&limit=${limit}`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const findFreeCards = async (
  teacherId,
  gradeId,
  subjectId,
  month,
  year,
  skip,
  limit
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/class-fee?fee_type=FULL_FREE&teacherId=${teacherId}&gradeId=${gradeId}&subjectId=${subjectId}&month=${month}&year=${year}&skip=${skip}&limit=${limit}`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const findHalfFreeCards = async (
  teacherId,
  gradeId,
  subjectId,
  month,
  year,
  skip,
  limit
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/class-fee?fee_type=HALF_FREE&teacherId=${teacherId}&gradeId=${gradeId}&subjectId=${subjectId}&month=${month}&year=${year}&skip=${skip}&limit=${limit}`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
