import {
    Table,
    Card,
    CardHeader,
    Modal,
    Button,
    ModalBody,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Spinner,
} from "reactstrap";
// core components
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { findClassFees, downloadClassFeesCsvFile } from "actions/Teachers";
import { useNavigate, useParams } from "react-router-dom";
import { HiDownload } from "react-icons/hi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/css/styles.css";
import Paginations from "components/Pagination/Paginations";
import "../../assets/css/toggle-button-styles.css";

const ClassTeacherDailyPaymentRecords = () => {
    const { teacherClassId, date } = useParams();
    const [teacherClassPayments, setTeacherClassPayments] = useState(null);
    const [isTeacherClassPayments, setIsTeacherClassPayments] = useState(false);

    const navigate = useNavigate();
    const [isLoadingStudents, setLoadingStudents] = useState(true);
    const [selectedDate, setSelectedDate] = useState(date);
    const [isFailed, setFailed] = useState(false);
    const [isErrorMessage, setErrorMessage] = useState("");
    const [isChecked, setIsChecked] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPge, SetItemsPerPage] = useState(10);
    const [skip, setSkip] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [currentStart, setCurrentStart] = useState(0);
    const monthOrDate = "date";
    const paymentType = "DAILY";

    const getPaymentDetails = async (status, selectedDate, pageNumber) => {
        const teacherClassPayments = await findClassFees(
            teacherClassId,
            status,
            paymentType,
            monthOrDate,
            selectedDate,
            pageNumber,
            itemsPerPge
        );
        setTeacherClassPayments(teacherClassPayments.data);
        setLoadingStudents(false);
        setIsTeacherClassPayments(true);
        setPageCount(teacherClassPayments.pageCount);
        setCurrentPage(pageNumber + 1);
    }

    const fetchData = async () => {
        try {
            await getPaymentDetails(isChecked, selectedDate, skip);
        } catch (error) {
            setLoadingStudents(false);
            setIsTeacherClassPayments(false);
        }
    };

    const filterAttendanceByDate = async (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const newDate = year + "-" + month + "-" + day;
        setSelectedDate(newDate);
        await getPaymentDetails(isChecked, newDate, skip);
        setCurrentStart(0);
        navigate(
            `/admin/teachers/daily-payment-records/${teacherClassId}/${newDate}`
        );
    }

    const downloadBlobDailyPayments = async () => {
        try {
            await downloadClassFeesCsvFile(teacherClassId,
                isChecked,
                paymentType,
                monthOrDate,
                selectedDate,
                skip,
                itemsPerPge);
        } catch (error) {
            setFailed(true);
            setErrorMessage(error.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleToggle = async (checkedStatus) => {
        if (checkedStatus) {
            await getPaymentDetails(checkedStatus, selectedDate, skip);
            setCurrentStart(0);
        } else {
            await getPaymentDetails(checkedStatus, selectedDate, skip);
            setCurrentStart(0);
        }
    }

    const handleChange = () => {
        const newCheckedStatus = !isChecked;
        setIsChecked(newCheckedStatus);
        handleToggle(newCheckedStatus);
    };

    const handlePagination = async (pageNumber) => {
        await getPaymentDetails(isChecked, selectedDate, pageNumber - 1);
    }

    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                <Modal
                    className="modal-dialog modal-danger"
                    isOpen={isFailed}
                    toggle={() => setFailed(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isErrorMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setFailed(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                {/* Display Tabel */}
                {isLoadingStudents ? (
                    <Card style={{}}>
                        <Spinner className="m-10">Loading...</Spinner>
                    </Card>
                ) : !isTeacherClassPayments ? (
                    <Card className="text-center" style={{ padding: "1rem" }}>
                        <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data Available" />
                    </Card>
                ) : (
                    <Card className="shadow" style={{ padding: "1rem" }}>
                        <CardHeader className="border-0">
                            <Row className="headerSectionPayment">
                                <div className="toggleSection">                                  
                                <h3 className="mb-0">Payment Records</h3>
                                    <label className="custom-toggle">
                                        <input
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleChange}
                                        />
                                        <span className="custom-toggle-slider rounded-circle" />
                                        {isChecked ? <p className="present">
                                            Paid </p> : <p className="absent">Unpaid</p>}
                                    </label>
                                </div>
                                <Col>
                                    <Row className="paymentRecordsDownload">
                                        <DatePicker
                                            showIcon
                                            selected={selectedDate}
                                            onChange={(date) => filterAttendanceByDate(date)}
                                            className="dateSelector"
                                            icon="fa fa-calendar"
                                        />
                                    </Row>
                                    <Row className="paymentRecordsDownload">
                                        <FormGroup className="mb-0">
                                            <Button
                                                color="primary"
                                                type="button"
                                                onClick={() => downloadBlobDailyPayments()}
                                                disabled={!teacherClassPayments.length > 0}
                                                className="exportBtn"
                                            >
                                                <HiDownload /> Export
                                            </Button>
                                        </FormGroup>
                                    </Row>
                                </Col>
                            </Row>
                        </CardHeader>
                        <Table className="align-items-center" responsive>
                            <thead className="thead-light">
                                <tr>{isChecked ?
                                    <th scope="col">Date</th> : null}
                                    <th scope="col">Reg. No</th>
                                    <th scope="col">Full Name</th>
                                    <th scope="col">Amount</th>
                                    {isChecked ?
                                    <th scope="col">Scholarship</th> : null}
                                </tr>
                            </thead>
                            {teacherClassPayments && teacherClassPayments.length > 0 ? (
                                <tbody>
                                    {teacherClassPayments?.map((teacherClassPayment, index) => (
                                        <tr key={index}>
                                            {teacherClassPayment.created_at !== null ?
                                                <td>{teacherClassPayment.created_at.split("T")[0]}</td>
                                                : null
                                            }
                                            <td>
                                                {
                                                    teacherClassPayment.student_registration_number
                                                }
                                            </td>
                                            <td>
                                                {
                                                    teacherClassPayment.student_full_name
                                                }
                                            </td>
                                            <td>
                                                {teacherClassPayment.price}
                                            </td>
                                            {isChecked? 
                                            <td>
                                                {teacherClassPayment.isScholarship
                                                    ? "Active"
                                                    : "Inactive"}
                                            </td>
                                            : null}                                          
                                        </tr>
                                    ))}
                                </tbody>
                            ) :
                                <tbody>
                                    <tr>
                                        <td colSpan={12} style={{ textAlign: "center" }}>
                                            <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data Available" />
                                        </td>
                                    </tr>
                                </tbody>
                            }
                        </Table>
                        <div style={{ paddingTop: "10px" }}>
                            {pageCount > 1 ?
                                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                                    currentStart={currentStart}
                                    setCurrentStart={setCurrentStart}></Paginations>
                                : null}
                        </div>
                    </Card>
                )}
            </div>
        </>
    );
};

export default ClassTeacherDailyPaymentRecords;
