import axios from "axios";
const apiUrl = "https://ebis.ardillalabs.cloud/api";

// Course Categories
export const getClassDetailsByTeacherId = async (teacherId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/teacher-class/find-by-teacher-id/${teacherId}`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
            response: {
              data: {
                message: err,
                status: err.code,
                statusText: err.code,
              },
            },
          });
      }
    }
  });
};
