import {
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { findClassFees, downloadClassFeesCsvFile } from "actions/Teachers";
import { useNavigate, useParams } from "react-router-dom";
import { HiDownload } from "react-icons/hi";
import "../../../assets/css/toggle-button-styles.css";
import Paginations from "components/Pagination/Paginations";
import "../../../assets/css/styles.css";
import Messages from "components/Messages/Messages";

const ClassTeacherPaymentRecords = () => {
  const { teacherClassId, month } = useParams();
  const [teacherClassPayments, setTeacherClassPayments] = useState(null);
  const [isTeacherClassPayments, setIsTeacherClassPayments] = useState(false);

  const navigate = useNavigate();
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [isLoadingStudents, setLoadingStudents] = useState(true);
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(month);
  const [isFailed, setFailed] = useState(false);
  const [isErrorMessage, setErrorMessage] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, SetItemsPerPage] = useState(10);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);
  const monthOrDate = "month";
  const paymentType = "MONTHLY";

  const getPaymentDetails = async (status, selectedMonth, pageNumber) => {
    const teacherClassPayments = await findClassFees(
      teacherClassId,
      status,
      paymentType,
      monthOrDate,
      selectedMonth,
      pageNumber,
      itemsPerPge
    );
    setTeacherClassPayments(teacherClassPayments.data);
    setLoadingStudents(false);
    setIsTeacherClassPayments(true);
    setPageCount(teacherClassPayments.pageCount);
    setCurrentPage(pageNumber + 1);
  }

  const fetchData = async () => {
    try {
      const startDate = new Date();
      const endDate = new Date();
      startDate.setMonth(startDate.getMonth() - (12 - 1));
      const startMonth = startDate.getMonth();
      const endMonth = endDate.getMonth();
      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();
      const months = [];

      for (let year = startYear; year <= endYear; year++) {
        const monthStart = year === startYear ? startMonth : 0;
        const monthEnd = year === endYear ? endMonth : 11;

        for (let month = monthStart; month <= monthEnd; month++) {
          const monthString = `${year}-${(month + 1)
            .toString()
            .padStart(2, "0")}`;
          months.push({
            label: monthString,
            month: month + 1,
            year: year,
          });
        }
      }
      setMonths(months);
      await getPaymentDetails(isChecked, selectedMonth, skip);
    } catch (error) {
      setLoadingStudents(false);
      setIsTeacherClassPayments(false);
    }
  };

  const filterClick = async (label) => {
    setSelectedMonth(label);
    await getPaymentDetails(isChecked, label, skip);
    setCurrentStart(0);
    navigate(
      `/high-level-admin/teachers/payment-records/${teacherClassId}/${label}`
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleToggle = async (checkedStatus) => {
    if (checkedStatus) {
      await getPaymentDetails(checkedStatus, selectedMonth, skip);
      setCurrentStart(0);
    } else {
      await getPaymentDetails(checkedStatus, selectedMonth, skip);
      setCurrentStart(0);
    }
  }

  const handleChange = () => {
    const newCheckedStatus = !isChecked;
    setIsChecked(newCheckedStatus);
    handleToggle(newCheckedStatus);
  };

  const handlePagination = async (pageNumber) => {
    await getPaymentDetails(isChecked, selectedMonth, pageNumber - 1);
  }

  const handleSendMessage = async(response) => {
    if(response.status === true) {
      setSuccess(true);
      setSuccessMessage(response.message);
    } else {
      setFailed(true);
      setErrorMessage(response.message);
    }
  };

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
      <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        {/* Display Tabel */}
        {isLoadingStudents ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isTeacherClassPayments ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data Available" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="border-0">
              <Row className="headerSectionPayment">
                <div className="toggleSection">
                  <h3 className="mb-0">Payment Records</h3>
                  <label className="custom-toggle">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleChange}
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                    {isChecked ? <p className="present">
                      Paid </p> : <p className="absent">Unpaid</p>}
                  </label>
                </div>
                <Col>
                  <Row className="paymentRecordsDownloadMonthly">
                    <FormGroup className="mb-2">
                      <Dropdown
                        isOpen={statusDropdownOpen}
                        toggle={() =>
                          setStatusDropdownOpen(!statusDropdownOpen)
                        }
                        className="filterButton"
                      >
                        <DropdownToggle caret>
                          {selectedMonth ? selectedMonth : "Filter by Month"}
                        </DropdownToggle>
                        {months && months.length > 0 ? (
                          <DropdownMenu>
                            {months.map((month) => (
                              <DropdownItem
                                key={month.label}
                                value={month.label}
                                onClick={() =>
                                  filterClick(
                                    month.label
                                  )
                                }
                              >
                                {month.label}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        ) : null}
                      </Dropdown>
                    </FormGroup>
                  </Row>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>{isChecked ?
                  <th scope="col">Date</th> : null}
                  <th scope="col">Reg. No</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Amount</th>
                  {isChecked ?
                    <th scope="col">Scholarship</th> : null}
                </tr>
              </thead>
              {teacherClassPayments && teacherClassPayments.length > 0 ? (
                <tbody>
                  {teacherClassPayments?.map((teacherClassPayment, index) => (
                    <tr key={index}>
                      {isChecked ?
                        <td>{teacherClassPayment.created_at?.split("T")[0]}</td>
                        : null
                      }
                      <td>
                        {
                          teacherClassPayment.student_registration_number
                        }
                      </td>
                      <td>
                        {
                          teacherClassPayment.student_full_name
                        }
                      </td>
                      <td>
                        {teacherClassPayment.price}
                      </td>
                      {isChecked ?
                        <td>
                          {teacherClassPayment.isScholarship
                            ? "Active"
                            : "Inactive"}
                        </td>
                        : null}
                    </tr>
                  ))}
                </tbody>
              ) :
                <tbody>
                  <tr>
                    <td colSpan={12} style={{ textAlign: "center" }}>
                      <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data Available" />
                    </td>
                  </tr>
                </tbody>
              }
            </Table>
            <div style={{ paddingTop: "10px" }}>
              {pageCount > 1 ?
                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                  currentStart={currentStart}
                  setCurrentStart={setCurrentStart}></Paginations>
                : null}
            </div>
          </Card>
        )}
      </div>
    </>
  );
};

export default ClassTeacherPaymentRecords;
