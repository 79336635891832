import {
  FormGroup,
  Form,
  Input,
  InputGroup,
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { addNewGrade } from "actions/Grades";
import { editGrade } from "actions/Grades";
import { getGradesBySearch } from "actions/Grades";
import { getGradesByLimit } from "actions/Grades";
import Paginations from "components/Pagination/Paginations";
import { BiEdit } from "react-icons/bi";

const Grades = () => {
  const [allGrades, setAllGrades] = useState(null);
  const [isGrades, setIsGrades] = useState(false);
  const [isLoadingGrades, setLoadingGrades] = useState(true);
  const [editModal, setEditmodal] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState({
    id: null,
    name: "",
    status: "",
  });
  const [isData, setData] = useState({
    gradeName: "",
    status: "",
  });
  const [errors, setErrors] = useState({});
  const [editErrors, setEditErrors] = useState({});
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isEdited, setEdit] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, SetItemsPerPage] = useState(10);
  const [skip, setSkip]= useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);
  const [disabledInsertBtn, setDisabledInsertBtn] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [initialGrade, setInitialGrade] = useState({
    id: null,
    name: "",
    status: "",
  });

  const fetchData = async (itemsPerPge, skip) => {
    try {
      const Grades = await getGradesByLimit(itemsPerPge, skip);
      setPageCount(Grades.data.pageCount);
      setAllGrades(Grades.data.data);
      setLoadingGrades(false);
      setIsGrades(true);
    } catch (error) {
      setLoadingGrades(false);
      setIsGrades(false);
    }
  };

  useEffect(() => {
    fetchData(itemsPerPge, skip);
  }, []);

  const searchGrade = async (searchQuery) => {
    const searched = await getGradesBySearch(itemsPerPge, skip, searchQuery);
    setPageCount(searched.data.pageCount);
    setAllGrades(searched.data.data);
  };

  const filterBySearch = async (itemsPerPge, skip, searchQuery) => {
    const searched = await getGradesBySearch(itemsPerPge, skip, searchQuery);
    setPageCount(searched.data.pageCount);
    setAllGrades(searched.data.data);
    setCurrentPage(skip + 1);
  }

  const handlePagination = async (pageNumber) => {
    if (searchQuery !== " ") {
      await filterBySearch(itemsPerPge, pageNumber - 1, searchQuery);
    } else {
      const features = await fetchData(itemsPerPge, pageNumber - 1);
      setCurrentPage(pageNumber);
    }
  }
  

  const handleInputChange = (values) => {
    setErrors({ grade: "" });
    setData({
      ...isData,
      ...values,
    });
  };

  // Validate input
  const isValidGrade = (value) => {
    if (value.length < 1) {
      setErrors({
        grade: "Grade name must be at least one character long.",
      });
      return false;
    } else {
      setErrors({ grade: "" });
      return true;
    }
  };

  const insertNewGrade = async () => {
    if (isValidGrade(isData.gradeName)) {
      setDisabledInsertBtn(true);
      const response = await addNewGrade(isData.gradeName);
      if (response.success === true) {
        setSuccessMessage(response.message);
        setSuccess(true);
        setData({
          gradeName: "",
        });
        fetchData(itemsPerPge, skip);
        setDisabledInsertBtn(false);
      } else {
        setErrorMessage(response.message);
        setFailed(true);
        setData({
          gradeName: "",
        });
        setDisabledInsertBtn(false);
      }
    }
  };

  const handleEditClick = (grade) => {
    setSelectedGrade(grade);
    setInitialGrade(grade);
    setEditmodal(true);
  };

  // Validate input
  const isValidEditedGrade = (value) => {
    if (value.name.length < 1) {
      setEditErrors({
        name: "Grade name must be at least one character long.",
      });
      return false;
    } else {
      setEditErrors({ name: "" });
      return true;
    }
  };

  function getChangedValues(selectedGrade) {
    const changedValues = {};
    for (const key in selectedGrade) {
      if (
        key !== "id" &&
        selectedGrade.hasOwnProperty(key) &&
        selectedGrade[key] !== initialGrade[key]
      ) {
        changedValues[key] = selectedGrade[key];
      }

      if (key === "id") {
        changedValues[key] = selectedGrade[key];
      }
    }
    return changedValues;
  }

  const editGradeDetails = async () => {
    const validityCheck = isValidEditedGrade(selectedGrade);
    if (validityCheck === true) {
      setEdit(false);
      const data = getChangedValues(selectedGrade);
      const body = JSON.stringify(data);
      const response = await editGrade(body);
      if (response.success === true) {
        setSuccessMessage(response.message);
        setSuccess(true);
        setData({
          gradeName: "",
        });    
        fetchData(itemsPerPge, skip);
        setEditmodal(false);
      } else {
        setFailed(true);
      }
    }
  };

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Card className="mb-4 shadow">
          <CardHeader className="border-0">
            <Row>
              <Col md="6" xs="12">
                <span>
                  <h3 className="mb-0">Add New Grade</h3>
                </span>
              </Col>
            </Row>
          </CardHeader>
          <Form className="ml-4 mb-4 mr-4">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Input
                    id="grade"
                    placeholder="Grade"
                    type="text"
                    value={isData.gradeName}
                    required
                    onChange={(e) =>
                      handleInputChange({ gradeName: e.target.value })
                    }
                  />
                  {errors.grade && (
                    <p className="text-danger">{errors.grade}</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Button
                  color="primary"
                  type="button"
                  onClick={() => insertNewGrade()}
                  disabled={disabledInsertBtn}
                >
                  Insert
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
        {isLoadingGrades ? (
          <Card>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isGrades ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage"/>
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "2rem" }}>
            <Row>
              <Col>
                <h3 className="mb-0">Grades</h3>
              </Col>
              <Col className="justify-content-end" md="4">
                <InputGroup className="mb-3">
                  <Input
                    type="text"
                    placeholder="Search by grade"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      setCurrentPage(1);
                      setCurrentStart(0);
                      searchGrade(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allGrades?.map((grade, index) => (
                      <tr key={index}>
                        <td>{grade.name}</td>
                        <td>{grade.status ? "Active" : "Inactive"}</td>
                        <td>
                          <Button
                            color="primary"
                            type="button"
                            id="editGrade"
                            onClick={() => handleEditClick(grade)}
                          >
                             <BiEdit />
                      </Button>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
            <div style={{paddingTop: "10px"}}>
          {pageCount > 1 ?
              <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                currentStart={currentStart}
                setCurrentStart={setCurrentStart}></Paginations>
              : null}
          </div>
          </Card>
        )}
        <Modal
          className="modal-dialog-centered"
          isOpen={editModal}
          toggle={() => {
            setEditmodal(false);
            editErrors({ name: "" });
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Grade
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setEditmodal(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Input
                      id="exampleFormControlInput1"
                      placeholder="Grade Name"
                      type="text"
                      value={selectedGrade ? selectedGrade.name : ""}
                      onChange={(e) => {
                        setSelectedGrade({
                          id: selectedGrade.id,
                          name: e.target.value,
                          status: selectedGrade.status,
                        });
                        setEdit(true);
                      }}
                    />
                    {editErrors.name && (
                      <p className="text-danger">{editErrors.name}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    {/* <Inpus */}
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle caret>
                        {selectedGrade
                          ? selectedGrade.status
                            ? "Active"
                            : "Inactive"
                          : "Select Status"}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={(e) => {
                            setSelectedGrade({
                              id: selectedGrade.id,
                              name: selectedGrade.name,
                              status: true,
                            });
                            setEdit(true);
                          }}
                        >
                          Active
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) => {
                            setSelectedGrade({
                              id: selectedGrade.id,
                              name: selectedGrade.name,
                              status: false,
                            });
                            setEdit(true);
                          }}
                        >
                          Inactive
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Button
                  color="primary"
                  type="button"
                  disabled={!isEdited}
                  onClick={() => editGradeDetails()}
                  style={{ marginLeft: "15px" }}
                >
                  Save
                </Button>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default Grades;
