import React from "react";
import "../../assets/css/hero-styles.css";
import LandingImg from '../../assets/img/brand/landing_img_1.png';
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import AboutUs from '../../assets/img/brand/contact_us.png';
import ContactUs from '../../assets/img/brand/about_us.png';

const HeroSection = () => {
    const navigate = useNavigate();

    const openLogin = () => {
        return navigate("/auth/login");
    }
    return (
        <>
            <div className="heroContainer">
                <div className="heroSection">
                    <div className="heroSectionTopTextWrapper">
                        <h1>Boost Productivity with Smart <span style={{ color: "#4bb6df" }}>Institute Management System</span></h1>
                        <ul className="heroDescription">
                            <li>Record attendance via QR scanning</li>
                            <li>Send SMS notifications</li>
                            <li>Generate student IDs with QR codes</li>
                            <li>Register students in bulk</li>
                            <li>Manage all payment transactions</li>
                        </ul>
                        <div className="heroSectionLinks">
                            <Button
                                className="getBtn"
                                onClick={() => openLogin()}
                            >
                                Get Started
                            </Button>
                        </div>
                        <div className="giveUsCall">
                        <h2 className="startPhnNumber">Give us a call</h2>
                        <a href="tel:077 763 4902" className="contacttNumber">077 763 4902</a>
                        </div>
                    </div>
                    <div className="heroSectionImageWrapper">
                        <img
                            src={LandingImg}
                            alt="Landing"
                            className="heroSectionImage"
                        />
                    </div>
                </div>
            </div>
            <div className="aboutContainer">
                <div className="aboutUsSection">
                    <h1>About Us</h1>
                    <div className="aboutBottom">
                        <div className="aboutLeft">
                            <p>Our Institute Management Software represents a comprehensive solution specifically designed to
                                optimize the operational efficiency of educational institutions. Boasting an intuitive
                                user interface and robust functionality, it empowers administrators, teachers, and institutes
                                by providing seamless access to essential tools and information. By integrating all administrative
                                tasks into a single, cohesive system, our software saves time, reduces paperwork, and fosters
                                a more connected and productive educational environment. Embrace the future of education management
                                with our state-of-the-art software, meticulously crafted to meet the unique needs of modern institutes.</p>
                            <ul className="features">
                                <li>Record attendance via QR scanning</li>
                                <li>Send SMS notifications</li>
                                <li>Enable disable option in notification sending</li>
                                <li>Generate student IDs with QR codes</li>
                                <li>Register students in bulk</li>
                                <li>Manage all student payments</li>
                                <li>Manage all institute's income & expenses</li>
                                <li>Seperate accounts for admins, teachers and class assistants</li>
                                <li>Keep all transaction history</li>
                                <li>Due reports and statical graphs & charts</li>
                            </ul>
                        </div>
                        <div className="aboutRight">
                            <img src={AboutUs} alt="about us" className="aboutImg" />

                        </div>
                    </div>
                </div>
            </div>
            <div className="contactContainer">
                <div className="contactUsSection">
                    <h1>Contact Us</h1>
                    <div className="contactBottom">
                        <div className="contactLeft">
                            <img src={ContactUs} alt="about us" className="contactImg" />
                        </div>
                        <div className="contactRight">
                            <div className="contactDescription">
                                <p>For further information or to schedule a demonstration of our
                                    Institute Management Software, please contact us. Our dedicated
                                    support team is available to answer your queries and provide personalized
                                    assistance to help you understand how our solution can meet your institution's
                                    unique needs. We look forward to partnering with you to enhance
                                    the efficiency and effectiveness of your educational institution</p>
                            </div>
                            <div className="iconSection">
                                <div className="email">
                                    <div className="iconClass">
                                        <i className="fa fa-envelope fa-3x" aria-hidden="true"></i>
                                    </div>
                                    <div className="emailDetails">
                                        <p className="contactTitle">Email Address</p>
                                        <a href="mailto:support@ardillalabs.com">support@ardillalabs.com</a>
                                    </div>
                                </div>
                                <div className="phoneNumber">
                                    <div className="iconClass">
                                        <i className="fa fa-phone fa-4x" aria-hidden="true"></i>
                                    </div>
                                    <div className="emailDetails">
                                        <p className="contactTitle">Phone Number</p>
                                        <a href="tel:077 763 4902">077 763 4902</a>
                                    </div>
                                </div>
                                <div className="location">
                                    <div className="iconClass">
                                        <i className="fa fa-map-marker fa-3x" aria-hidden="true"></i>
                                    </div>
                                    <div className="emailDetails">
                                        <p className="contactTitle">Address</p>
                                        <p style={{fontSize: "20px", color: "#4ab7e0", fontWeight: "500"}}>No 16, Ambaraluwa North, Weliweriya</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footerSection">
                <div className="footerContent">
                    © {new Date().getFullYear()}{" "}
                    <a
                        className="font-weight-bold ml-1"
                        href="https://www.ardillalabs.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Ardilllabs
                    </a>
                </div>
            </div>
        </>
    );
};

export default HeroSection;
