import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/desktop-nav-styles.css";
import LogoImg from "../../assets/img/brand/ebis-logo.png";
import { Button } from "reactstrap";

const DesktopNav = () => {
  const navigate = useNavigate();
  useEffect(() => {
  }, []);

  const openLogin = () => {
    return navigate("/auth/login");
  }

  return (
    <>
      <nav className="navbarLanding">
        <div className="navbarWrapper">
          <div className="navbarLeft">
            <img
              src={LogoImg}
              className="logoImage"
              alt="IMS logo"
            />
          </div>
          <div className="navbarRight">
          <Button
              className="startBtn"
              onClick={() => openLogin()}
            >
              Get Started
            </Button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default DesktopNav;
