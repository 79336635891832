import React, { useEffect, useState } from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import { Container, Row } from "reactstrap";
import routes from "routes.js";
import { useNavigate } from 'react-router-dom';
import { getUser } from "actions/Auth";
import Loader from "views/pages/Loader";

const Auth = () => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const user = await getUser();
      setUser(user);
      setUserDetails(true);
      setUserLoading(false);
    } catch (error) {
      setUserDetails(true);
      setUserLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        if(userLoading === true){
          return 'Loading....!'
        } else if(userLoading === false){
          if(userDetails && user === null) {
            return (
              <Route path={prop.path} element={prop.component} key={key} exact />
            );
          } else {          
            return navigate('/admin/index');
          }
        }
        return navigate('/admin/dashboard');
      }
    else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <div className="header bg-gradient-info py-7 py-lg-8">
          <div className="separator separator-bottom separator-skew zindex-100">
           
          </div>
        </div>
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/auth/login" replace />} />
            </Routes>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Auth;
