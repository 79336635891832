import axios from "axios";
const apiUrl = "https://ebis.ardillalabs.cloud/api"; 

// Grades
export const getThisMonthPaidAllStudentCount = async (year, month) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/class-fee/get-count?year=${year}&month=${month}&status=true`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const getAllStudentCount = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.get(
          `${apiUrl}/users/get-student-count`,
          config
        );
  
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject(err);
        }
      }
    });
  };

  export const getAllClassCount = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.get(
          `${apiUrl}/teacher-class/get-count?status=true`,
          config
        );
  
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject(err);
        }
      }
    });
  };

  export const getGraphData = async (months, all) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.get(
          `${apiUrl}/class-fee/get-class-fee-count-by-month/${months}/${all}`,
          config
        );
  
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject(err);
        }
      }
    });
  };

  export const getThisMonthPaidAllStudentCountbyTeacher = async (year, month, teacherId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.get(
          `${apiUrl}/class-fee/get-count?year=${year}&month=${month}&teacherId=${teacherId}&status=true`,
          config
        );
  
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject(err);
        }
      }
    });
  };

  export const getAllStudentCountByTeacher = async (teacherId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.get(
          `${apiUrl}/class-user/get-count/?status=true&teacherId=${teacherId}`,
          config
        );
  
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject(err);
        }
      }
    });
  };

  export const getAllClassCountByTeacher = async (teacherId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.get(
          `${apiUrl}/teacher-class/get-count/?status=true&teacherId=${teacherId}`,
          config
        );
  
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject(err);
        }
      }
    });
  };

  export const getInstituteIncomeByMonth = async (year) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.get(
          `${apiUrl}/class-fee/institute-this-year-incomes/${year}`,
          config
        );
  
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject(err);
        }
      }
    });
  };

  export const getTeacherIncomeByMonth = async (year, teacherId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.get(
          `${apiUrl}/class-fee/teacher-this-year-incomes/${year}/${teacherId}`,
          config
        );
  
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject(err);
        }
      }
    });
  };

  export const getDailyBalance = async (date) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
        
        const response = await axios.get(
          `${apiUrl}/class-fee/today-institute-balance/${date}`,
          config
        );
  
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject(err);
        }
      }
    });
  };