import {
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
  Label,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { Link, useParams } from "react-router-dom";
import { getAllTeachers } from "actions/Teachers";
import { getAllSubjects } from "actions/Subjects";
import { getAllGrades } from "actions/Grades";
import { editTeacherClassDetails } from "actions/Teachers";
import { findClassesByTeacherIdForAdmin } from "actions/Teachers";
import { BiEdit } from "react-icons/bi";
import { HiDownload } from "react-icons/hi";
import { downloadMonthlyIncomeReport } from "actions/Teachers";

const ClassTeacherDetails = () => {
  const { id } = useParams();
  const [teacherClasses, setTeacherClasses] = useState(null);
  const [isTeacherClasses, setIsTeacherClasses] = useState(false);

  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [selectedTeacherClass, setSelectedTeacherClass] = useState(null);
  const [editModal, setEditmodal] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [isLoadingStudents, setLoadingStudents] = useState(true);
  const [isEditedInput, setEditedInput] = useState(false);
  const [isDisabledEditButton, setDisabledEditButton] = useState(true);
  const [today, setToday] = useState(null);
  const [todayDate, setTodayDate] = useState(null);
  const [previousMonth, setPreviousMonth] = useState(null);
  const [teacherDropdownOpen, setTeacherDropdownOpen] = useState(false);
  const [subjectDropdownOpen, setSubjectDropdownOpen] = useState(false);
  const [gradeDropdownOpen, setGradeDropdownOpen] = useState(false);
  const [selectedTeacherForClass, setSelectedTeacherForClass] = useState(null);
  const [selectedSubjectForClass, setSelectedSubjectForClass] = useState(null);
  const [selectedGradeForClass, setSelectedGradeForClass] = useState(null);
  const [allSubjets, setAllSubjects] = useState(null);
  const [allGrades, setAllGrades] = useState(null);
  const [allTeachers, setAllTeachers] = useState(null);
  const [initialTeacherClass, setInitialTeacherClass] = useState({
    id: null,
    teacherId: null,
    subjectId: null,
    gradeid: null,
    status: "",
  });

  const [isTeacherClassError, setTeacherClassError] = useState({
    teacherId: "",
    gradeId: "",
    subjectId: "",
    price: "",
    class_year: "",
  });

  const fetchData = async () => {
    try {
      const teacherClasses = await findClassesByTeacherIdForAdmin(id);
      setTeacherClasses(teacherClasses);
      const teachers = await getAllTeachers();
      setAllTeachers(teachers);
      const subjects = await getAllSubjects();
      setAllSubjects(subjects);
      const grades = await getAllGrades();
      setAllGrades(grades);
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // Month is zero-indexed
      const day = currentDate.getDate();
      setToday({
        year: year,
        month: month,
        day: day,
      });
      setTodayDate({
        year: year,
        month: month.toString().padStart(2, "0"),
        day: day.toString().padStart(2, "0"),
      });
      currentDate.setMonth(currentDate.getMonth() - 1);
      const yearPrevious = currentDate.getFullYear();
      const monthPrevious = String(currentDate.getMonth() + 1).padStart(2, '0');
      setPreviousMonth({
        year: yearPrevious,
        month: monthPrevious
      });
      setLoadingStudents(false);
      if (teacherClasses.length > 0) {
        setIsTeacherClasses(true);
      }
    } catch (error) {
      setLoadingStudents(false);
      setIsTeacherClasses(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditClick = (teacherClass) => {
    setSelectedTeacherClass(teacherClass);
    setInitialTeacherClass(teacherClass);
    setEditmodal(true);
  };

  const isValidTeacherClass = (value) => {
    const errors = {};
    if (value.price.length < 3) {
      errors.price =
        "Price must be at least 3 characters long.";
    }
    if (value.year.length < 1) {
      errors.year = "Year must be at 4 characters long.";
    }
    if (Object.keys(errors).length > 0) {
      setTeacherClassError(errors);
      return false;
    } else {
      return true;
    }
  };

  function getChangedValues(selectedTeacherClass) {
    const changedValues = {};
    changedValues.id = selectedTeacherClass.id;
    changedValues.teacherId = selectedTeacherClass.teacher.id;
    changedValues.gradeId = selectedTeacherClass.grade.id;
    changedValues.subjectId = selectedTeacherClass.subject.id;
    for (const key in selectedTeacherClass) {
      if (
        key !== "id" &&
        selectedTeacherClass.hasOwnProperty(key) && initialTeacherClass.hasOwnProperty(key) &&
        selectedTeacherClass[key] !== initialTeacherClass[key]
      ) {
        changedValues[key] = selectedTeacherClass[key];
      }
    }
    return changedValues;
  }

  const editTeacherClass = async () => {
    setDisabledEditButton(true);
    const validityCheck = isValidTeacherClass(selectedTeacherClass);
    if (validityCheck === true) {
      setEditedInput(false);
      const data = getChangedValues(selectedTeacherClass);
      const body = JSON.stringify(data);
      const response = await editTeacherClassDetails(body);
      if (response.success === true) {
        setSuccessMessage(response.message);
        setSuccess(true);
        fetchData();
        setEditmodal(false);
      } else {
        setFailed(true);
      }
    }
  }

  const downloadBlob = async () => {
    try {
      await downloadMonthlyIncomeReport(`${todayDate.year}-${todayDate.month}`, teacherClasses[0]?.teacher.id, teacherClasses[0]?.teacher.full_name);
    } catch (error) {
      setFailed(true);
      setErrorMessage(error.message);
    }
  };

  const downloadBlobPreviousMonth = async () => {
    try {
      await downloadMonthlyIncomeReport(`${previousMonth.year}-${previousMonth.month}`, teacherClasses[0]?.teacher.id, teacherClasses[0]?.teacher.full_name);
    }
    catch (error) {
      setFailed(true);
      setErrorMessage(error.message);
    }
  };
  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        {/* Display Tabel */}
        {isLoadingStudents ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isTeacherClasses ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="border-0">
              <Row className="headerSection">
                <div className="teacherClassTitle">
                  <h3 className="mb-0">Classes of {teacherClasses[0]?.teacher?.full_name}</h3>
                </div>
                <div className="btnSection" >
                  <FormGroup className="mb-0">
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => downloadBlob()}
                      disabled={!teacherClasses.length > 0}
                      className="monthBtn"
                    >
                      <HiDownload /> This Month
                    </Button>
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => downloadBlobPreviousMonth()}
                      disabled={!teacherClasses.length > 0}
                      className="monthBtn"
                    >
                      <HiDownload /> Previous Month
                    </Button>
                  </FormGroup>
                </div>
              </Row>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Grade</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Class Fee</th>
                  <th scope="col">Status</th>
                  <th scope="col">Payment Type</th>
                  <th colSpan={4} style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {teacherClasses?.map((teacherClass, index) => (
                  <tr key={index}>
                    <td>{teacherClass.grade.name}</td>
                    <td>{teacherClass.subject.name}</td>
                    <td>{teacherClass.price}</td>
                    <td>{teacherClass.status ? "Active" : "Inactive"}</td>
                    <td>{teacherClass.payment_type}</td>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        color="default"
                        type="button"
                        tag={Link}
                        to={`/admin/teachers/classStudents/${teacherClass.id}`}
                      >
                        Students
                      </Button>
                      {teacherClass.payment_type === "MONTHLY"?
                       <Button
                       color="default"
                       type="button"
                       tag={Link}
                       to={`/admin/teachers/payment-records/${teacherClass.id}/${todayDate.year}-${todayDate.month}`}
                     >
                       Payment Records
                     </Button>:
                      <Button
                      color="default"
                      type="button"
                      tag={Link}
                      to={`/admin/teachers/daily-payment-records/${teacherClass.id}/${todayDate.year}-${todayDate.month}-${todayDate.day}`}
                    >
                      Payment Records
                    </Button>
                      }
                      <Button
                        color="default"
                        type="button"
                        tag={Link}
                        to={`/admin/teachers/attendance-records/${teacherClass.id}/${todayDate.year}-${todayDate.month}-${todayDate.day}`}
                      >
                        Attendance
                      </Button>
                      <Button
                        color="primary"
                        type="button"
                        id="editClass"
                        onClick={() => handleEditClick(teacherClass)}
                      >
                        <BiEdit />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}
        {/* Edit teacherClass */}
        <Modal
          className="modal-dialog-centered"
          isOpen={editModal}
          toggle={() => [setEditmodal(false)]}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Class
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => [
                setEditmodal(false),
                setDisabledEditButton(true),
                setTeacherClassError({
                  price: "",
                  class_year: "",
                }),
              ]}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form className="ml-4 mb-4 mr-4">
              <Row>
                <Col>
                  <FormGroup>
                    <Dropdown
                      isOpen={teacherDropdownOpen}
                      toggle={() =>
                        setTeacherDropdownOpen(!teacherDropdownOpen)
                      }
                    >
                      <DropdownToggle caret>
                        {selectedTeacherClass ? selectedTeacherClass.teacher.full_name : "Select a Teacher"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {allTeachers && allTeachers.map((teacher) => (
                          <DropdownItem
                            key={teacher.full_name}
                            value={teacher.id}
                            onClick={() => [
                              setSelectedTeacherClass({
                                ...selectedTeacherClass, teacher: teacher
                              }),
                              setSelectedTeacherForClass(teacher),
                              setDisabledEditButton(false)
                            ]}
                          >
                            {teacher.full_name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                  <FormGroup>
                    <Dropdown
                      isOpen={subjectDropdownOpen}
                      toggle={() =>
                        setSubjectDropdownOpen(!subjectDropdownOpen)
                      }
                    >
                      <DropdownToggle caret>
                        {selectedTeacherClass ? selectedTeacherClass.subject.name : "Select a Subject"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {allSubjets && allSubjets.map((subject) => (
                          <DropdownItem
                            key={subject.name}
                            value={subject.id}

                            onClick={() => [
                              setSelectedTeacherClass({
                                ...selectedTeacherClass, subject: subject
                              }),
                              setSelectedSubjectForClass(subject),
                              setDisabledEditButton(false)
                            ]}
                          >
                            {subject.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                  <FormGroup>
                    <Dropdown
                      isOpen={gradeDropdownOpen}
                      toggle={() =>
                        setGradeDropdownOpen(!gradeDropdownOpen)
                      }
                    >
                      <DropdownToggle caret>
                        {selectedTeacherClass ? selectedTeacherClass.grade.name : "Select a Grade"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {allGrades && allGrades.map((grade) => (
                          <DropdownItem
                            key={grade.name}
                            value={grade.id}
                            onClick={() => [
                              setSelectedTeacherClass({
                                ...selectedTeacherClass, grade: grade
                              }),
                              setSelectedGradeForClass(grade),
                              setDisabledEditButton(false)
                            ]}
                          >
                            {grade.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                  <FormGroup>
                    <Label>Previous Class Fee</Label>
                    <Input
                      id="price"
                      placeholder="Class Fee"
                      type="number"
                      value={selectedTeacherClass?.prev_price || ''}
                      name="price"
                      onChange={(e) => {
                        setSelectedTeacherClass({
                          ...selectedTeacherClass,
                          prev_price: e.target.value,
                        });
                        setDisabledEditButton(false)
                      }}
                    />
                    {isTeacherClassError.price && (
                      <p className="text-danger">{isTeacherClassError.price}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                  <Label>Current Class Fee</Label>
                    <Input
                      id="newPrice"
                      placeholder="New Class Fee"
                      type="number"
                      value={selectedTeacherClass?.price || ''}
                      name="newPrice"
                      onChange={(e) => {
                        setSelectedTeacherClass({
                          ...selectedTeacherClass,
                          price: e.target.value,
                        });
                        setDisabledEditButton(false)
                      }}
                    />
                    {isTeacherClassError.newClassFee && (
                      <p className="text-danger">{isTeacherClassError.newClassFee}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Input
                      id="class_year"
                      placeholder="Class Year"
                      type="number"
                      value={selectedTeacherClass?.year}
                      onChange={(e) => {
                        setSelectedTeacherClass({
                          ...selectedTeacherClass,
                          year: e.target.value,
                        });
                        setDisabledEditButton(false)
                      }}
                      name="class_year"
                    />
                    {isTeacherClassError.class_year && (
                      <p className="text-danger">{isTeacherClassError.class_year}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Dropdown
                      isOpen={statusDropdownOpen}
                      toggle={() => setStatusDropdownOpen(!statusDropdownOpen)}
                    >
                      <DropdownToggle caret>
                        {selectedTeacherClass
                          ? selectedTeacherClass.status
                            ? "Active"
                            : "Inactive"
                          : "Select Status"}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => [
                            setSelectedTeacherClass({
                              ...selectedTeacherClass,
                              status: true,
                            }), setDisabledEditButton(false)]
                          }
                        >
                          Active
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => [
                            setSelectedTeacherClass({
                              ...selectedTeacherClass,
                              status: false,
                            }), setDisabledEditButton(false)]
                          }
                        >
                          Inactive
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                  <Button
                    color="primary"
                    type="button"
                    onClick={editTeacherClass}
                    disabled={
                      isDisabledEditButton
                    }
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default ClassTeacherDetails;
