import React from 'react';
import Barcode from 'react-barcode';

const TemporaryReceipt = React.forwardRef((props, ref) => {
  return (
    <>
      <style>
        {`
        @media print {
            @page {
                size: 80mm 60mm;
                margin: 0;
            }
            .print-container {
                width: 72mm;
                margin: 0 auto;
                color: black !important;
            }
            .print-content {
                text-align: center;
                padding: 10mm 0mm;
            }
            .barcode {
                width: 100%;
            }
        }
    `}
      </style>
      <div ref={ref} style={{ textAlign: "center", color: 'black' }} className='printContainer'>
        <div className='print-content'>
          <div style={{marginTop: 20, marginBottom: 40, paddingBottom: 40}}>
            <h1 style={{ fontSize: 64, color: 'black', fontWeight: '1000', textAlign: "center", textDecoration: "underline" }}>Temporary Card</h1>
          </div>
          <h2 style={{ fontSize: 36, color: 'black', textAlign: "center", marginBottom: 20, marginTop: 40 }}>{props.allDetails[1]}</h2>
          <div className="barcode">
            <Barcode style={{ textAlign: "center" }} value={props.allDetails[0]} width={6} height={200} fontSize={40} />
          </div>
          <hr style={{height: 2}}/>
          <div>
            <h2 style={{ fontSize: 25, color: 'black', fontWeight: 'bold', textAlign: "center", marginTop: 40 }}>EBIS INSTITUTE</h2>
          </div>
        </div>
      </div>
    </>
  );
});

export default TemporaryReceipt;