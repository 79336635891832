import {
    Table,
    Card,
    CardHeader,
    Row,
    Col,
    Spinner,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";

import "../../../assets/css/styles.css";
import Paginations from "components/Pagination/Paginations";
import { findPaymentCancel } from "actions/Teachers";
import DatePicker from "react-datepicker";
import { getUser } from "actions/Auth";

const TeacherStudentPayCancel = () => {
    const [allStudents, setAllStudents] = useState(null);
    const [isAllStudents, setIsAllStudents] = useState(false);
    const [isLoadingStudents, setLoadingStudents] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPge, SetItemsPerPage] = useState(10);
    const [skip, setSkip] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [currentStart, setCurrentStart] = useState(0);

    const [user, setUser] = useState(null);

    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const freshDate = year + "-" + month + "-" + day;
    const [selectedDate, setSelectedDate] = useState(freshDate);

    const getStudents = async (selectedDate, itemsPerPge, skip) => {
        const students = await findPaymentCancel(user.id, selectedDate, skip, itemsPerPge);
        setPageCount(students.data.pageCount);
        setAllStudents(students.data);
        if (students.data.length > 0) {
            setIsAllStudents(true);
        }
    }

    const fetchData = async () => {
        try {
            const user = await getUser();
            setUser(user);
            await getStudents(selectedDate, itemsPerPge, skip);
            setLoadingStudents(false);
        } catch (error) {
            setLoadingStudents(false);
            setIsAllStudents(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filterByDate = async (date) => {
        try {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const day = date.getDate().toString().padStart(2, "0");
            const newDate = year + "-" + month + "-" + day;
            setSelectedDate(newDate);
            await getStudents(newDate, itemsPerPge, skip)
        } catch (error) {
            setAllStudents(null);
        }
    }

    const handlePagination = async (pageNumber) => {
        await getStudents(selectedDate, itemsPerPge, pageNumber - 1);
        setCurrentPage(pageNumber);
    }

    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">               
            <Card className="shadow" style={{ padding: "1rem" }}>
                        <CardHeader className="border-0">
                            <Row>
                                <Col>
                                    <h3 className="mb-0">Cancelled Payments</h3>
                                </Col>
                        <Col className="justify-content-end" md="8" lg="4">
                            <DatePicker
                                showIcon
                                selected={selectedDate}
                                onChange={(date) => filterByDate(date)}
                                className="dateSelector"
                                icon="fa fa-calendar"
                                placeholderText="Filter by Date"
                                fixedHeight="47px"
                            />
                        </Col>                    
                        </Row>
                        </CardHeader>
                {isLoadingStudents ? (
                        <Spinner className="m-10">Loading...</Spinner>
                ) : !isAllStudents ? (
                        <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" />
                  ) : (
                    <>
                        <Table className="align-items-center" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Invoice No</th>
                                    <th scope="col">Full Name</th>
                                    <th scope="col">Barcode</th>
                                    <th scope="col">Contact Number</th>
                                    <th scope="col">Month</th>
                                    <th scope="col">Fee Type</th>
                                    <th scope="col">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allStudents?.map((student, index) => (
                                    <tr key={index}>
                                        <td>{student.invoice_number}</td>
                                        <td>{student.class_user.user.full_name}</td>
                                        <td>{student.class_user.user.barcode}</td>
                                        <td>{student.class_user.user.phone_number}</td>                                        
                                        <td>{student.year}-{String(student.month).padStart(2, '0')}</td>
                                        <td>{student.fee_type}</td>
                                        <td>{student.amount}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div style={{ paddingTop: "10px" }}>
                            {pageCount > 1 ?
                                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                                    currentStart={currentStart}
                                    setCurrentStart={setCurrentStart}></Paginations>
                                : null}
                        </div>
                        </>
                )}
            </Card>
            </div >
        </>
    );
};

export default TeacherStudentPayCancel;
