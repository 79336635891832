const SecondaryHeader = () => {
  return (
    <>
      <div className="header bg-gradient-info pb-4 pt-5 pt-md-8">
      </div>
    </>
  );
};

export default SecondaryHeader;
