import React from 'react';
import { Table } from "reactstrap";

const TeacherPaymentReceipt = React.forwardRef((props, ref) => {
    return (
        <>
            <style>
                {`
                    @media print {
                        @page {
                            size: 80mm 140mm;
                            margin: 0;
                        }
                        .teacherTableContent {
                           text-align: center;
                            padding: 10mm 10mm;
                        }
                        .payTitle, .receiptTitle, .receiptTitle1, .receiptTitle2 {
                            margin: 0;
                            padding: 0;
                        }
                        .payTable th, .payTable td{
                            border: 1mm solid black;
                        }
                    }
                `}
            </style>
            <div className="payReceipt" ref={ref}>
                <div className='teacherTableContent' style={{ padding: "20mm 10mm", textAlign: "left" }}>
                    <Table className="mt-2 payTable" responsive>
                        {props.allDetails[0]?.total !== "0" ? (
                            <>
                                <h1 style={{ fontSize: 36, color: 'black', fontWeight: 'bold', textAlign: "left", textDecoration: "underline" }}>Payment Summary Report</h1>
                                <h2 style={{ fontSize: 36, color: 'black'}}>{props.allDetails[1]}</h2>
                                <h2 style={{ fontSize: 36, color: 'black'}}>Date or Month: {props.allDetails[2]}</h2>
                                <tbody className="mb-4">
                                    <tr>
                                        <td colSpan="2" style={{ fontSize: 34, color: 'black', fontWeight: 'bold' }}>
                                            {props.allDetails[0]?.subject} - {props.allDetails[0]?.grade}
                                        </td>
                                    </tr>
                                    <tr><td style={{ fontSize: 32, color: 'black', lineHeight: '1.2'}}>Paid Normal</td>
                                        <td style={{ fontSize: 32, color: 'black', lineHeight: '1.2'}}>{props.allDetails[0]?.full_count}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: 32, color: 'black', lineHeight: '1.2'}}>Half Card</td>
                                        <td style={{ fontSize: 32, color: 'black', lineHeight: '1.2'}}>{props.allDetails[0]?.half_free_count}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: 32, color: 'black', lineHeight: '1.2'}}>Free Card</td>
                                        <td style={{ fontSize: 32, color: 'black', lineHeight: '1.2'}}>{props.allDetails[0]?.full_free_count}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: 32, color: 'black', lineHeight: '1.2'}}>Previous</td>
                                        <td style={{ fontSize: 32, color: 'black', lineHeight: '1.2'}}>{props.allDetails[0]?.prev_count}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: 34, color: 'black', fontWeight: 'bold'}}>Commission Rate</td>
                                        <td style={{ fontSize: 34, color: 'black', fontWeight: 'bold'}}>{props.allDetails[0]?.teacher_percentage}%</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: 34, color: 'black', fontWeight: 'bold'}}>Total Amount</td>
                                        <td style={{ fontSize: 34, color: 'black', fontWeight: 'bold'}}>{parseFloat(props.allDetails[0]?.total).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: 34, color: 'black', fontWeight: 'bold'}}>Settlement Amount</td>
                                        <td style={{ fontSize: 34, color: 'black', fontWeight: 'bold'}}>{parseFloat(props.allDetails[0]?.settle_total).toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </>
                        ) : null}
                    </Table>
                </div>
            </div>
        </>
    );
});

export default TeacherPaymentReceipt;

