import { addMessageService } from "actions/MessageService";
import { getMessageService } from "actions/MessageService";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Modal, Row, Spinner } from "reactstrap";
import "../../assets/css/toggle-button-styles.css";

const MessageService = () => {
    const [errors, setErrors] = useState({});
    const [selectedOption, setSelectedOption] = useState("");
    const [isApi, setApi] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isFailed, setFailed] = useState(false);
    const [isSuccessMessage, setSuccessMessage] = useState("");
    const [isErrorMessage, setErrorMessage] = useState("");
    const [isLoading, setLoading] = useState(true);

    const [isData, setData] = useState({
        userId: "",
        apiKey: "",
        senderId: "",
    });

    const fetchData = async () => {
        try {
        const response = await getMessageService();
        setLoading(false);
        if (response.api_key !== null) {
            setApi(true);
            setData({
                userId: response.user_id, apiKey: response.api_key, senderId: response.sender_id, status: response.status, attendance: response.attendance,
                class_fee_to_parents: response.class_fee_to_parents, class_fee_to_teachers: response.class_fee_to_teachers
            });
            setAllChecked(response.status);
            setIsChecked(response.attendance);
            setCheckedPaymentParents(response.class_fee_to_parents);
            setCheckedPaymentTeachers(response.class_fee_to_teachers);
            setSelectedOption(response.status === true ? "Enabled" : "Disabled")
        }
    } catch (error) {
        setLoading(false);
        setFailed(true);
        setErrorMessage(error.response.message);
    }
    }

    const [isAllChecked, setAllChecked]= useState(isData.status);
    const [isChecked, setIsChecked] = useState(isData.attendance);
    const [isCheckedPaymentParents, setCheckedPaymentParents] = useState(isData.class_fee_to_parents);
    const [isCheckedPaymentTeachers, setCheckedPaymentTeachers] = useState(isData.class_fee_to_teachers);

    useEffect(() => {
        fetchData();
    }, []);

    const handleInputChange = (values) => {
        setErrors({});
        setData({
            ...isData,
            ...values,
        });
    };

    const insertMessageService = async () => {
        try {
            const response = await addMessageService(isData);
            setSuccess(true);
            setSuccessMessage(response.message);
            fetchData();
        } catch (error) {
            setFailed(true);
            setErrorMessage(error.response.message);
        }
    }

    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                <Modal
                    className="modal-dialog-centered modal-success"
                    isOpen={isSuccess}
                    toggle={() => setSuccess(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isSuccessMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setSuccess(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Modal
                    className="modal-dialog modal-danger"
                    isOpen={isFailed}
                    toggle={() => setFailed(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isErrorMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setFailed(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Card className="mb-4 shadow">
                    <CardHeader className="border-0">
                        <Row>
                            <Col md="6" xs="12">
                                <span>
                                    <h3 className="mb-0">Message Service</h3>
                                </span>
                            </Col>
                        </Row>
                    </CardHeader>
                    {isLoading ? <Spinner></Spinner> : (
                        <Form className="ml-4 mb-4 mr-4">
                            <Row>
                                <Col md="12" lg="6">
                                    <FormGroup>
                                        <Input
                                            id="userId"
                                            placeholder="User Id"
                                            type="text"
                                            value={isData.userId}
                                            required
                                            onChange={(e) =>
                                                handleInputChange({ userId: e.target.value })
                                            }
                                        />
                                        {errors.userId && (
                                            <p className="text-danger">{errors.userId}</p>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            id="apiKey"
                                            placeholder="Api Key"
                                            type="text"
                                            value={isData.apiKey}
                                            required
                                            onChange={(e) =>
                                                handleInputChange({ apiKey: e.target.value })
                                            }
                                        />
                                        {errors.apiKey && (
                                            <p className="text-danger">{errors.apiKey}</p>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            id="senderId"
                                            placeholder="Sender Id"
                                            type="text"
                                            value={isData.senderId}
                                            required
                                            onChange={(e) =>
                                                handleInputChange({ senderId: e.target.value })
                                            }
                                        />
                                        {errors.senderId && (
                                            <p className="text-danger">{errors.senderId}</p>
                                        )}
                                    </FormGroup>
                                    <FormGroup className="toggle-section">
                                        <Col>
                                            <label className="toggle-label">All Notification Services</label>
                                        </Col>
                                        <label className="custom-toggle">
                                            <input
                                                type="checkbox"
                                                checked={isAllChecked}
                                                onChange={() => {
                                                    setAllChecked(!isAllChecked);
                                                    setData({
                                                        ...isData,
                                                        status: !isAllChecked,
                                                    });
                                                }}
                                            />

                                            <span className="custom-toggle-slider rounded-circle" />
                                            {isAllChecked ? <p className="present">
                                                Enabled </p> : <p className="toggle-dis">Disabled</p>}
                                        </label>
                                    </FormGroup>
                                    {isAllChecked?
                                    <>
                                    <FormGroup className="toggle-section">
                                        <Col>
                                            <label className="toggle-label">Automatic message of attendance</label>
                                        </Col>
                                        <label className="custom-toggle">
                                            <input
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={() => {
                                                    setIsChecked(!isChecked);
                                                    setData({
                                                        ...isData,
                                                        attendance: !isChecked,
                                                    });
                                                }}
                                            />

                                            <span className="custom-toggle-slider rounded-circle" />
                                            {isChecked ? <p className="present">
                                                Enabled </p> : <p className="toggle-dis">Disabled</p>}
                                        </label>
                                    </FormGroup>
                                    <FormGroup className="toggle-section">
                                        <Col>
                                            <label className="toggle-label">Automatic message of payments to parents</label>
                                        </Col>
                                        <label className="custom-toggle">
                                            <input
                                                type="checkbox"
                                                checked={isCheckedPaymentParents}
                                                onChange={() => {
                                                    setCheckedPaymentParents(!isCheckedPaymentParents);
                                                    setData({
                                                        ...isData,
                                                        class_fee_to_parents: !isCheckedPaymentParents,
                                                    });
                                                }}
                                            />
                                            <span className="custom-toggle-slider rounded-circle" />
                                            {isCheckedPaymentParents ? <p className="present">
                                                Enabled </p> : <p className="toggle-dis">Disabled</p>}
                                        </label>
                                    </FormGroup>
                                    <FormGroup className="toggle-section">
                                        <Col>
                                            <label className="toggle-label">Automatic message of payments to teachers</label>
                                        </Col>
                                        <label className="custom-toggle">
                                            <input
                                                type="checkbox"
                                                checked={isCheckedPaymentTeachers}
                                                onChange={() => {
                                                    setCheckedPaymentTeachers(!isCheckedPaymentTeachers);
                                                    setData({
                                                        ...isData,
                                                        class_fee_to_teachers: !isCheckedPaymentTeachers,
                                                    });
                                                }}
                                            />
                                            <span className="custom-toggle-slider rounded-circle" />
                                            {isCheckedPaymentTeachers ? <p className="present">
                                                Enabled </p> : <p className="toggle-dis">Disabled</p>}
                                        </label>
                                    </FormGroup>
                                    </>
                                    : null }
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <Button
                                        color="primary"
                                        type="button"
                                        onClick={() => insertMessageService()}
                                        disabled={!isData.userId || !isData.apiKey || !isData.senderId}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Card>
            </div>
        </>
    );
}

export default MessageService;